<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="propsDialog" fullscreen>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="editedItem = {}; propsDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-html="editedItem.full_name + '\'s Properties'"></span>
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-title class="pt-0">
                    <ContactProperties :contactId="editedItem.id"/>
                  </v-card-title>
                </v-card>
              </v-dialog>
              <v-card-title>
                Shared Contacts By Others ({{ addTSBVZ(totalRecords) }})
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-card-text v-if="!isLoaded">
                <v-row v-if="items.length > 0">
                  <v-col cols="12" v-for="(item, index) in items" :key="index">
                    <v-card
                        class="mx-auto custom-card"
                        outlined
                        rounded
                    >
                      <v-toolbar
                          height="40"
                          dark
                          color="#00004d"
                      >
                        <v-toolbar-title>
                          <span
                              v-if="item.type_of_contact && item.type_of_contact === '0'"
                              v-html="item.salutation ? item.salutation.title : ''">
                          </span> <span v-html="item.full_name"></span>
                          <span class="font-weight-bold" v-if="item.pud_props_count > 1"
                                v-html="' (' + item.pud_props_count + ' Properties)'"></span>
                          <span class="font-weight-bold" v-if="item.pud_props_count === 1"
                                v-html="' (' + item.pud_props_count + ' Property)'"></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                          <v-btn
                              v-if="item.pud_props_count > 0"
                              dark
                              text
                              @click="editedItem = item; propsDialog = true"
                          >
                            View Properties
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('contacts.update')"
                              dark
                              text
                              @click="$emit('updateSharedContacts', item)"
                          >
                            Edit
                          </v-btn>
<!--                          <v-btn-->
<!--                              v-if="hasPermission('contacts.archive')"-->
<!--                              dark-->
<!--                              text-->
<!--                          >-->
<!--                            Archive-->
<!--                          </v-btn>-->
                        </v-toolbar-items>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <!--                          <v-col cols="12" sm="3">-->
                          <!--                            <v-img :lazy-src="getImagePlaceHolder()" :src="getPicture(item)"-->
                          <!--                                   class="listing_gallery_image"></v-img>-->
                          <!--                          </v-col>-->
                          <v-col cols="12" sm="3">
                            <h6>Email</h6>
                            <p v-if="item.emails" v-html="replaceCommaLine(item.emails)"></p>
                            <p v-else>-</p>

                            <h6>Phone</h6>
                            <p v-if="item.phones" v-html="replaceCommaLine(item.phones)"></p>
                            <p v-else>-</p>

                            <h6>Passport Number</h6>
                            <p v-if="item.passport_numbers" v-html="replaceCommaLine(item.passport_numbers)"></p>
                            <p v-else>-</p>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <h6>Nationality</h6>
                            <p v-if="item.nationalities" v-html="replaceCommaLine(item.nationalities)"></p>
                            <p v-else>-</p>

                            <h6>National/Resident's Identification</h6>
                            <p v-if="item.national_ids" v-html="replaceCommaLine(item.national_ids)"></p>
                            <p v-else>-</p>

                            <h6>Website</h6>
                            <p v-if="item.websites" v-html="replaceCommaLine(item.websites)"></p>
                            <p v-else>-</p>

                            <span v-if="item.type_of_contact === '0'">
                              <h6>Date Of Birth</h6>
                              <p v-if="item.dob" v-html="item.dob"></p>
                              <p v-else>-</p>
                            </span>

                            <span v-if="item.type_of_contact === '0'">
                              <h6>Gender</h6>
                              <p v-if="item.gender === '0'">Male</p>
                              <p v-else-if="item.gender === '1'">Female</p>
                              <p v-else>-</p>
                            </span>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <h6>Role/Group</h6>
                            <p v-html="item.contact_role_titles ? item.contact_role_titles : '-'"></p>
                            <h6>Source</h6>
                            <p v-html="item.source ? item.source.title : '-'"></p>
                            <h6>Domain/Sector/Category</h6>
                            <p v-html="item.contact_domain_titles ? item.contact_domain_titles : '-'"></p>
                            <h6>Occupation/Profession</h6>
                            <p v-html="item.contact_occupation_titles ? item.contact_occupation_titles : '-'"></p>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <ContactComments
                                @commentSaved="refreshComments($event, item)"
                                :contact="item"
                                :commentTitles="cTFilter"
                                :contactCommentsOld="item.contact_comments ? item.contact_comments : item.contact_comments === []"
                            ></ContactComments>
                          </v-col>
                        </v-row>
                      </v-card-text>
<!--                      <v-toolbar-->
<!--                          height="30"-->
<!--                          dark-->
<!--                          color="primary"-->
<!--                      >-->
<!--                        <v-toolbar-title>-->
<!--                          Created at:-->
<!--                          {{ formatDateAndTime(item.created_at) }} by-->
<!--                          {{ item.created_user_name }}-->
<!--                        </v-toolbar-title>-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <v-toolbar-title>-->
<!--                          Updated at:-->
<!--                          {{ formatDateAndTime(item.updated_at) }} by-->
<!--                          {{ item.updated_user_name }}-->
<!--                        </v-toolbar-title>-->
<!--                      </v-toolbar>-->
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <p class="text-center"><i>No Record Found.</i></p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0 custom-datatable"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer
                      class="custom-datatable-footer2"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="rowsPerPageItems"
                      :show-first-last-page="true"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {bus} from "@/main";
import {
  SHARED_CONTACTS_LIST,
} from "@/core/services/store/contacts.module";
import ContactComments from "@/view/pages/realstate/contacts_management/inc/ContactComments";
import ContactProperties from "@/view/pages/realstate/contacts_management/inc/ContactProperties";
import {COMMENTTITLES_SIMPLE_LIST} from "@/core/services/store/commenttitles.module";

export default {
  name: 'shared-contacts',
  components: {
    ContactComments,
    ContactProperties
  },
  props: ['searchFormData', 'advSHitted'],
  data() {
    return {
      draw: 1,
      searchQuery: "",
      loading: true,
      isLoaded: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      headers: [
        {
          text: 'Name',
          value: 'full_name',
          data: 'full_name',
          name: 'full_name',
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "comment_updated_at",
          name: "comment_updated_at",
          filterable: false,
          sortable: true
        },
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      items: [],
      propsDialog: false,
      editedItem: {},
      cTFilter: [],
    }
  },
  watch: {
    // params: {
    //   handler() {
    //     this.loadData().then(response => {
    //       this.items = response.items;
    //       this.totalRecords = response.total;
    //     });
    //   },
    //   deep: true
    // },
    advSHitted: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.loadData().then(response => {
      this.items = response.items;
      this.totalRecords = response.total;
      this.getCommentTitles();
    });
    bus.$on('contactUpdatedByParent', res => {
      let fItem = this.items.find(ele => ele.id === res.records.id);
      if (fItem) {
        fItem.full_name = res.records.full_name;
        fItem.contact_role_titles = res.records.contact_role_titles;
        fItem.contact_domain_titles = res.records.contact_domain_titles;
        fItem.contact_occupation_titles = res.records.contact_occupation_titles;
        fItem.nationality = res.records.nationality;
        fItem.salutation = res.records.salutation;
        fItem.source = res.records.source;
        fItem.emails = res.records.emails;
        fItem.phones = res.records.phones;
        fItem.passport_numbers = res.records.passport_numbers;
        fItem.nationalities = res.records.nationalities;
        fItem.national_ids = res.records.national_ids;
        fItem.websites = res.records.websites;
      }
    });
  },
  methods: {
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        if (loadDep) this.getCommentTitles();
      });
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(SHARED_CONTACTS_LIST, {q: query, columns: this.columns}).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    getCommentTitles() {
      this.$store.dispatch(COMMENTTITLES_SIMPLE_LIST).then((data) => {
        this.cTFilter = data.records.commentTitlesList;
      });
    },
    refreshComments(comment, item) {
      item.contact_comments = [comment, ...item.contact_comments]
      // item.contact_comments.push(comment);
      item.comment_count++;
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.contacts.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>
<template>
  <v-card>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeSmsDlg')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span v-html="'Send SMS'"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        <span
            v-if="$store.state.auth.user.sms_quota === 0">Your SMS Quota is 0</span>
        <span v-else
              v-html="'Your SMS Quota is ' + $store.state.auth.user.used_sms_quota + ' / ' + $store.state.auth.user.sms_quota"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
            :disabled="!validationRules.valid"
            dark
            text
            @click="sendSms"
        >
          Send
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-col cols="12" class="pb-0" v-if="errors!=undefined && errors.length >= 0">
      <div
           role="alert"
           v-bind:class="{ show: errors.length }"
           class="alert fade alert-danger m-0"
      >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
          {{ error }}
        </div>
      </div>
    </v-col>
    <v-row>
      <v-col cols="12">
        <v-card-text>
          <v-form
              v-model="validationRules.valid"
              lazy-validation
              ref="sms-form"
          >
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                    return-object
                    outlined
                    dense
                    :items="phoneNumbers"
                    v-model="phones"
                    :rules="validationRules.multipleRules"
                    item-text="phone"
                    item-value="id"
                    label="Send To*"
                    chips
                    multiple
                    small-chips
                    deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-textarea outlined
                            dense
                            clearable
                            v-model="message"
                            maxLength="125"
                            :counter="125"
                            height="300"
                            :rules="validationRules.msgRule"
                            label="Message*">

                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {CLEAR_CONTACT_ERRORS, SEND_SMS} from "@/core/services/store/contacts.module";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";

export default {
  name: 'send-sms',
  props: ['phoneNumbers'],
  data() {
    return {
      message: '',
      phones: [],
      validationRules: {
        valid: true,
        msgRule: [
          v => !!v || "This field is required",
          v => (v && v.length <= 125) || "Must be less than or equal to 125 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select at least one number";
            else return true;
          }
        ]
      },
    }
  },
  watch: {
    phoneNumbers() {
      this.phones = this.phoneNumbers
      this.message = ''
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
    }
  },
  mounted() {
    this.phones = this.phoneNumbers
  },
  methods: {
    sendSms() {
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      this.$store.dispatch(SEND_SMS, {phones:this.phones, message: this.message}).then(() => {
        this.$emit('closeSmsDlg');
        this.message = ''
        this.$store.dispatch(VERIFY_AUTH)
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      })
    }
  },
  computed: {
    ...mapState({
      errors: state => state.contacts.errors
    })
  }
};
</script>
<template>
  <div class="comments-section">
    <h6>Comments
      <v-icon v-if="cTyoe === 'Save'" @click="getComments(true)" color="primary" style="cursor: pointer">mdi-refresh
      </v-icon>
    </h6>
    <v-row v-show="cTyoe === 'Save'">
      <v-col cols="12">
        <div class="form-group" v-for="(comment,k) in comments" :key="k" style="margin: 0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded outlined raised
                      v-bind="attrs"
                      v-on="on"
              >
                <v-icon
                    title="Delete Comment"
                    @click="deleteComment(comment)"
                    v-if="$store.state.auth.user.is_sub_admin === 1 || $store.state.auth.user.is_primary === 1 || $store.state.auth.user.id == comment.created_by"
                    dense color="red" style="float:right; cursor: pointer">mdi-close
                </v-icon>
                <v-icon
                    title="Edit Comment"
                    @click="editComment(comment)"
                    v-if="$store.state.auth.user.is_sub_admin === 1 || $store.state.auth.user.is_primary === 1 || $store.state.auth.user.id == comment.created_by"
                    dense color="primary" style="float:right; cursor: pointer">mdi-pencil
                </v-icon>
                <v-icon
                    title="View Property"
                    @click="$emit('viewListingFromComment', comment.contact, comment.listing_id)"
                    v-if="comment.listing_id && comment.listing_id !== 1"
                    dense color="warning" style="float:right; cursor: pointer">mdi-eye
                </v-icon>
                <!--            <v-card-title v-html="comment.created_user_name ? comment.created_user_name : '-&#45;&#45;'"></v-card-title>-->
                <!--            <v-card-subtitle v-html="formatDateAndTime(comment.created_at)"></v-card-subtitle>-->
                <v-card-text>
                  <div class="text--primary font-weight-bold" v-if="comment.comment_title"
                       v-html="comment.comment_title.title + '<br /><br />'"></div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.project">
                    <span>Project: </span>
                    <span v-html="comment.listing.project.country ? comment.listing.project.country.title : ''"></span>
                    <v-icon v-if="comment.listing.project.country" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span v-html="comment.listing.project.city ? comment.listing.project.city.title : ''"></span>
                    <v-icon v-if="comment.listing.project.city" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? comment.listing.project.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development.parent_development) ? comment.listing.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development) ? comment.listing.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development && comment.listing.project.development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development) ? comment.listing.project.development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.project.development && comment.listing.project.development.parent_development && comment.listing.project.development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.project.development && comment.listing.project.development.parent_development) ? comment.listing.project.development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.project.development && comment.listing.project.development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="comment.listing.project.development ? comment.listing.project.development.commercial_title : ''"></span>
                    <v-icon v-if="comment.listing.project.development" x-small class="ml-1 mr-1">fas
                      fa-angle-double-right
                    </v-icon>
                    <a class="hidden-md-and-up" :href="'/developments-management/projects?project=' + comment.listing.project.id">
                      <span v-html="'<strong>' + comment.listing.project.title + '</strong>'"></span>
                    </a>
                    <a class="hidden-sm-and-down" :href="'/developments-management/projects?project=' + comment.listing.project.id" target="_blank">
                      <span v-html="'<strong>' + comment.listing.project.title + '</strong>'"></span>
                    </a>
                  </div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.development">
                    <span>Development: </span>
                    <span v-html="comment.listing.development.country ? comment.listing.development.country.title : ''"></span>
                    <v-icon v-if="comment.listing.development.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                    <span v-html="comment.listing.development.city ? comment.listing.development.city.title : ''"></span>
                    <v-icon v-if="comment.listing.development.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                    <span
                        v-html="(comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? comment.listing.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development.parent_development) ? comment.listing.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development) ? comment.listing.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development && comment.listing.development.parent_development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development) ? comment.listing.development.parent_development.parent_development.commercial_title : ''"></span>
                    <v-icon
                        v-if="comment.listing.development && comment.listing.development.parent_development && comment.listing.development.parent_development.parent_development"
                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <span
                        v-html="(comment.listing.development && comment.listing.development.parent_development) ? comment.listing.development.parent_development.commercial_title : ''"></span>
                    <v-icon v-if="comment.listing.development && comment.listing.development.parent_development" x-small
                            class="ml-1 mr-1">fas fa-angle-double-right
                    </v-icon>

                    <a class="hidden-md-and-up" v-if="comment.listing.development.type === '0'" :href="'/developments-management/master-developments?development=' + comment.listing.development.id"
                    >
                      <span v-html="comment.listing.development.commercial_title"></span>
                    </a>
                    <a class="hidden-sm-and-down" v-if="comment.listing.development.type === '0'" target="_blank" :href="'/developments-management/master-developments?development=' + comment.listing.development.id"
                    >
                      <span v-html="comment.listing.development.commercial_title"></span>
                    </a>
                    <a class="hidden-md-and-up" v-if="comment.listing.development.type === '1'" :href="'/developments-management/sub-developments?development=' + comment.listing.development.id"
                    >
                      <span v-html="comment.listing.development.commercial_title"></span>
                    </a>
                    <a class="hidden-sm-and-down" v-if="comment.listing.development.type === '1'" target="_blank" :href="'/developments-management/sub-developments?development=' + comment.listing.development.id"
                    >
                      <span v-html="comment.listing.development.commercial_title"></span>
                    </a>
                  </div>
                  <div class="text--primary font-weight-bold" v-if="comment.date_contacted"
                       v-html="'Contacted Date:  ' + formatMomentDate(comment.date_contacted)"></div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.purpose"
                       v-html="'Purpose:  ' + comment.listing.purpose.title"></div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.property_type"
                       v-html="'Property Type:  ' + comment.listing.property_type.title"></div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.no_of_bedrooms">
                    No. Of Bedroom: {{ comment.listing.no_of_bedrooms }}
                  </div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.unit_no">
                    Unit/Plot Number: {{ comment.listing.unit_no }}
                  </div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.plot_area && comment.listing.plot_area_unit">
                    Plot Area: {{ comment.listing.plot_area_calc.hasOwnProperty($store.state.auth.user.measurement_unit.replace(/ /g, '_').toLowerCase()) ? addTSBV(comment.listing.plot_area_calc[$store.state.auth.user.measurement_unit.replace(/ /g, '_').toLowerCase()]) + ' ' + $store.state.auth.user.measurement_unit : '' }}
                  </div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.built_up_area && comment.listing.built_up_area_unit">
                    Built Up Area: {{ comment.listing.built_up_area_calc.hasOwnProperty($store.state.auth.user.measurement_unit.replace(/ /g, '_').toLowerCase()) ? addTSBV(comment.listing.built_up_area_calc[$store.state.auth.user.measurement_unit.replace(/ /g, '_').toLowerCase()]) + ' ' + $store.state.auth.user.measurement_unit : '' }}
                  </div>
                  <div class="text--primary font-weight-bold" v-html="'Rent Frequency: ' + comment.listing.rent_frequency.title" v-if="comment.listing && comment.listing.purpose && comment.listing.purpose.id === 2 && comment.listing.rent_frequency"></div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.tenancy_to && comment.listing.tenancy_to !== '1970-01-01'">
                    Rented Till: {{ formatMomentDate(comment.listing.tenancy_to) }}
                  </div>
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.tenancy_rent">
                    Rented At: {{ addTSBV(comment.listing.tenancy_rent) }}
                  </div>
                  <div class="text--primary font-weight-bold"
                       v-if="comment.comment_title && comment.comment_title.id === 13">
                    Date and Time: {{ formatDateAndTime(comment.date_time) }}
                  </div>
<!--                  <div class="text&#45;&#45;primary font-weight-bold" v-if="comment.listing && comment.listing.purpose && comment.listing.purpose.id === 1 && comment.listing.min_sale_price">-->
<!--                    Minimum Price: {{ addTSBV(comment.listing.min_sale_price) }}-->
<!--                  </div>-->
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.purpose && comment.listing.purpose.id === 1 && comment.listing.max_sale_price">
                    Selling Price: {{ addTSBV(comment.listing.max_sale_price) }}
                  </div>
<!--                  <div class="text&#45;&#45;primary font-weight-bold" v-if="comment.listing && comment.listing.purpose && comment.listing.purpose.id === 2 && comment.listing.min_rent_per_annum">-->
<!--                    Minimum Rent: {{ addTSBV(comment.listing.min_rent_per_annum) }}-->
<!--                  </div>-->
                  <div class="text--primary font-weight-bold" v-if="comment.listing && comment.listing.purpose && comment.listing.purpose.id === 2 && comment.listing.max_rent_per_annum">
                    Rent: {{ addTSBV(comment.listing.max_rent_per_annum) }}
                  </div>
                  <div class="text--primary" v-html="comment.comment ? replaceNewLine(comment.comment) : ''"></div>
                </v-card-text>
              </v-card>
              <br>
            </template>
            <span
                v-html="comment.created_user_name ? comment.created_user_name + ' at ' + formatDateAndTime(comment.created_at) : '-'"></span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <v-row v-show="cTyoe === 'Save'" v-if="contact.comment_count !== comments.length">
      <v-col cols="12">
        <a
            href="javascript:void(0)"
            :style="commentLoading ? 'pointer-events: none' : 'pointer-events: all'" @click="getComments">Show previous
          comments</a>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-autocomplete
            v-model="cItem.comment_title_id"
            :items="commentTitles"
            item-text="title"
            item-value="id"
            dense
            clearable
            outlined
            hide-details
            chips
            small-chips
            label="Title"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="pt-0" v-if="cItem.hasOwnProperty('comment_title_id') && cItem.comment_title_id === 13">
        <v-text-field
            outlined
            clearable
            dense
            hide-details
            type="date"
            label="Viewing Date"
            color="primary"
            v-model="cItem.date">
        </v-text-field>
      </v-col>
      <v-col cols="12" class="pt-0" v-if="cItem.hasOwnProperty('comment_title_id') && cItem.comment_title_id === 13">
        <v-text-field
            outlined
            clearable
            dense
            hide-details
            type="time"
            label="Viewing Time"
            color="primary"
            v-model="cItem.time">
        </v-text-field>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-textarea
            ref="commentContainer"
            outlined
            clearable
            dense
            hide-details
            label="Write Comment"
            color="primary"
            v-model="cItem.comment"
            maxlength="500"
            :counter="500">
        </v-textarea>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-btn :disabled="!cItem.hasOwnProperty('comment') || !cItem.comment" @click="saveComment" class="theme-btn"
               dark style="width: 100%">{{ cTyoe }}
        </v-btn>
        <v-btn v-if="cTyoe === 'Update'" @click="cTyoe = 'Save'; cItem = {}" color="primary" class="mt-2"
               dark style="width: 100%">Cancel
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {SAVE_COMMENT, GET_COMMENT, CLEAR_CONTACT_ERRORS, DELETE_COMMENT} from "@/core/services/store/contacts.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: 'contact-comments',
  props: ['contact', 'contactCommentsOld', 'commentTitles'],
  data() {
    return {
      cTyoe: 'Save',
      page: 1,
      cItem: {},
      comments: [],
      commentLoading: false
    }
  },
  watch: {
    contactCommentsOld(v) {
      this.comments = v;
    }
  },
  mounted() {
    this.comments = this.contactCommentsOld
  },
  methods: {
    saveComment() {
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      this.cItem.contact_id = this.contact.id
      this.$store.dispatch(SAVE_COMMENT, this.cItem).then((res) => {
        this.cItem = {}
        this.page = 1;
        if (this.cTyoe === 'Update') {
          this.cTyoe = 'Save'
          this.getComments(true)
        } else {
          this.$emit('commentSaved', res.records);
        }
      })
    },
    getComments(load = false) {
      this.commentLoading = true
      this.$store.dispatch(GET_COMMENT, {'contact_id': this.contact.id, page: this.page}).then((res) => {
        let that = this;
        if (res.records.data.length > 0) {
          if (this.page === 1) {
            if (load) {
              this.$emit('commentsReloaded', res.records.data);
            } else {
              this.comments = res.records.data;
            }
          } else {
            res.records.data.forEach(function (v) {
              that.comments.push(v)
            })
          }
          if (!load) this.page++;
          this.commentLoading = false;
        }
        if (load && res.records.data.length === 0) {
          this.$emit('commentsReloaded', res.records.data);
        }
      })
    },
    deleteComment(comment) {
      let that = this
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete this comment?", clr: 'red', callback: function () {
          that.$store.dispatch(CLEAR_CONTACT_ERRORS);
          that.$store.dispatch(DELETE_COMMENT, {id: comment.id}).then(() => {
            that.getComments(true)
            that.page = 1;
          })
        }
      });
    },
    editComment(comment) {
      // this.$nextTick(() => {
      //   let divv = this.$refs.commentContainer
      //   let scrollHeight = this.$refs.commentContainer.scrollHeight
      //   window.scrollTo(0, scrollHeight)
      // })
      this.cItem = comment
      this.cTyoe = 'Update'
    }
    // tChange(v){
    //   if (v) {
    //     if (v === 13) {
    //       alert(13)
    //     }
    //   }
    // }
  }
}
</script>
<template>
  <v-col cols="12">
    <h4>{{ moduleTitle }}</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="3">
          <v-autocomplete
              v-model="detail.card_id"
              :items="cardsFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Debit/Credit Card Issuer"
              :append-icon="'mdi-refresh'"
              @click:append="getCards"
          >
            <template v-slot:append-outer>
              <a target="_blank" href="/setup/cards?addModule=true"><v-icon>mdi-plus-circle-outline</v-icon></a>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Card Number"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.card_number">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              type="date"
              clearable
              outlined
              dense
              label="Expiry Date"
              color="primary"
              @keyup="update"
              v-model="detail.expiry_date">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="CSV"
              color="primary"
              maxlength="10"
              :counter="10"
              @keyup="update"
              v-model="detail.csv">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";
import {CARDS_SIMPLE_LIST} from "@/core/services/store/cards.module";

export default {
  name: 'contact-card-details',
  props: ['moduleOf', 'moduleTitle', 'contactCardDetailsOld'],
  data() {
    return {
      cardsFilter: [],
      details: [
        {card_id: '', card_number: '', expiry_date: '', csv: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.contacts.uploadedContactCardDetails = v;
      },
      deep: true
    },
    contactCardDetailsOld: {
      handler() {
        if (this.contactCardDetailsOld.length > 0) {
          this.details = this.contactCardDetailsOld
        } else {
          this.details = [
            {card_id: '', card_number: '', expiry_date: '', csv: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.contactCardDetailsOld.length > 0) {
      this.details = this.contactCardDetailsOld
    } else {
      this.details = [
        {card_id: '', card_number: '', expiry_date: '', csv: ''}
      ]
    }
    bus.$on("contactCardDetailsDeletedByModal", () => {
      this.details = [
        {card_id: '', card_number: '', expiry_date: '', csv: ''}
      ]
      this.$store.state.contacts.uploadedContactCardDetails = [];
    });
    this.getCards()
  },
  methods: {
    add() {
      this.details.push({card_id: '', card_number: '', expiry_date: '', csv: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
    getCards() {
      this.$store.dispatch(CARDS_SIMPLE_LIST).then((data) => {
        this.cardsFilter = data.records.cardsList;
      });
    },
  }
}
</script>
<template>
  <div class="row">
    <div class="col-md-12">
      <v-card>
        <v-card-title>
          Contacts Shared With ({{ addTSBVZ(totalRecords) }})
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="items"
            dense
            :options.sync="pagination"
            :server-items-length="totalRecords"
            :footer-props="{
                    itemsPerPageText:'Page no. ' + params.page + ' - Rows per page',
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
            :loading="loading"
            mobile-breakpoint="100"
            ref="mrc-contacts-properties-datatable"
            class="elevation-0 custom-td-table custom-td-table-scroll">
          <template v-slot:item.name="{ item }">
            <div class="text-truncate">
              <span v-html="item.user && item.user.vendor ? item.user.vendor.name + ' >> ' + item.user.name + ' >> ' + item.user.email : '-'"></span>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-truncate">
              <v-btn color="red" dark x-small @click="deleteItem(item)" class="mr-2">
                Remove
                <v-icon dark right x-small>fas fa-trash</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <!-- this dialog is used for both create and update -->
      </v-card>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  DELETE_SHARED_CONTACT_USER,SHARED_CONTACTS_USERS_LIST
} from "@/core/services/store/contacts.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: 'shared-contacts-users',
  props: ['doReload'],
  data() {
    return {
      headers: [
        {text: "Name", value: "name", name: "name", filterable: false, sortable: true},
        // {text: "Number Of Contacts Shared", value: "contacts_count", name: "contacts_count", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      items: [],
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    doReload(v){
      if (v) {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      }
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    // this.getPropertyTypes();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(SHARED_CONTACTS_USERS_LIST, {
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to remove", clr: 'red', callback: function () {
          let id = item.user.id;
          this.$store.dispatch(DELETE_SHARED_CONTACT_USER, {
            'user_id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.contacts.errors
    })
  }
};
</script>
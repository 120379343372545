<template>
  <v-col cols="12">
    <h4>{{ moduleTitle }}</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Bank Name"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.name">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Branch Name"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.branch_name">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Branch Code"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.branch_code">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              clearable
              outlined
              dense
              label="Swift Code"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.swift_code">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              clearable
              outlined
              dense
              label="Address"
              color="primary"
              maxlength="200"
              :counter="200"
              @keyup="update"
              v-model="detail.location">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              clearable
              outlined
              dense
              label="Account Number"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.account_no">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              clearable
              outlined
              dense
              label="IBAN Number"
              color="primary"
              maxlength="100"
              :counter="100"
              @keyup="update"
              v-model="detail.iban_no">
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";

export default {
  name: 'contact-bank-details',
  props: ['moduleOf', 'moduleTitle', 'contactBankDetailsOld'],
  data() {
    return {
      details: [
        {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
      ]
    }
  },
  watch: {
    details: {
      handler(v) {
        this.$store.state.contacts.uploadedContactBankDetails = v;
      },
      deep: true
    },
    contactBankDetailsOld: {
      handler() {
        if (this.contactBankDetailsOld.length > 0) {
          this.details = this.contactBankDetailsOld
        } else {
          this.details = [
            {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
          ]
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.contactBankDetailsOld.length > 0) {
      this.details = this.contactBankDetailsOld
    } else {
      this.details = [
        {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
      ]
    }
    bus.$on("contactBankDetailsDeletedByModal", () => {
      this.details = [
        {name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''}
      ]
      this.$store.state.contacts.uploadedContactBankDetails = [];
    });
  },
  methods: {
    add() {
      this.details.push({name: '', branch_name: '', branch_code: '', location: '', account_no: '', iban_no: ''});
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
  }
}
</script>
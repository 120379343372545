<template>
  <div class="col-md-12">
    <v-card>
      <LocationRemarksSelection
          @getLocationRemarks="getLocationRemarks"
          :lrFilter="locationRemarksFilter"
          :lrIdsFilter="locationRemarksIdsFilter"/>
      <v-dialog v-model="dialog" fullscreen
                transition="dialog-bottom-transition">
        <template v-slot:activator="{ on }">
          <div class="d-flex">
            <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
              <i style="color: #fff" class="flaticon2-refresh-1"></i>
            </v-btn>
            <v-btn color="#00004d" dark class="ma-3 ml-0" v-on="on">
              Add
              <v-icon small>mdi-plus-circle-outline</v-icon>
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-toolbar
              dark
              color="#00004d"
          >
            <v-btn
                icon
                dark
                @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
              <span v-else>Create Project Detail</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  :disabled="!validationRules.valid"
                  dark
                  text
                  @click="saveItem(editedItem)"
              >
                Save
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-form
                ref="form"
                v-model="validationRules.valid"
                lazy-validation
            >
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                placeholder="1 B/R, 2 B/R, 3 B/R etc."
                                dense v-model="editedItem.title"
                                maxLength="100"
                                :counter="100" :rules="validationRules.titleRules"
                                label="Title*"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                      v-model="editedItem.purpose_id"
                      :items="purposesFilter"
                      item-text="title"
                      item-value="id"
                      dense
                      outlined
                      deletable-chips
                      chips
                      small-chips
                      label="Purpose"
                      :append-icon="'mdi-refresh'"
                      @click:append="getPurposes(false)"
                  >
                    <template v-slot:append-outer>
                      <a target="_blank"
                         href="/setup/purposes?addModule=true">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </a>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" v-if="editedItem.purpose_id && editedItem.purpose_id == 1">
                  <v-text-field dense
                                outlined
                                v-model="editedItem.min_price"
                                maxLength="15"
                                :counter="15"
                                @keyup="editedItem.min_price = addTS($event)"
                                @keypress="isNumber($event)"
                                label="Minimum Price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" v-if="editedItem.purpose_id && editedItem.purpose_id == 1">
                  <v-text-field dense
                                outlined
                                v-model="editedItem.max_price"
                                maxLength="15"
                                :counter="15"
                                @keyup="editedItem.max_price = addTS($event)"
                                @keypress="isNumber($event)"
                                label="Maximum Price"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" v-if="editedItem.purpose_id && editedItem.purpose_id == 2">
                  <v-autocomplete
                      v-model="editedItem.rent_frequency_id"
                      :items="rfFilter"
                      item-text="title"
                      item-value="id"
                      dense
                      outlined
                      deletable-chips
                      chips
                      small-chips
                      label="Rent Frequency"
                      :append-icon="'mdi-refresh'"
                      @click:append="getRentFrequencies(false)"
                  >
                    <template v-slot:append-outer>
                      <a target="_blank"
                         href="/setup/rent-frequencies?addModule=true">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </a>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" v-if="editedItem.purpose_id && editedItem.purpose_id == 2">
                  <v-text-field dense
                                outlined
                                v-model="editedItem.min_rent"
                                maxLength="15"
                                :counter="15"
                                @keyup="editedItem.min_rent = addTS($event)"
                                @keypress="isNumber($event)"
                                label="Minimum Rent"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" v-if="editedItem.purpose_id && editedItem.purpose_id == 2">
                  <v-text-field dense
                                outlined
                                v-model="editedItem.max_rent"
                                maxLength="15"
                                :counter="15"
                                @keyup="editedItem.max_rent = addTS($event)"
                                @keypress="isNumber($event)"
                                label="Maximum Rent"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                      v-model="editedItem.project_id"
                      :items="projectsFilter"
                      item-text="title"
                      item-value="id"
                      clearable
                      outlined
                      dense
                      chips
                      deletable-chips
                      :loading="isProjectsLoaded"
                      small-chips
                      label="Projects (Minimum 3 Characters Required)"
                      @keyup="projectsFilterKeyUp"
                  >
                    <template v-slot:append-outer>
                      <a target="_blank"
                         href="/developments-management/projects?addModule=true">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </a>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-html="data.item.country ? data.item.country.title : ''"></span>
                            <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span v-html="data.item.city ? data.item.city.title : ''"></span>
                            <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.development && data.item.development.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                            <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                      v-model="editedItem.development_id"
                      :items="developmentsFilter"
                      item-text="commercial_title"
                      item-value="id"
                      clearable
                      outlined
                      dense
                      deletable-chips
                      chips
                      :loading="isDevelopmentsLoaded"
                      small-chips
                      label="Development (min. 3 characters required)"
                      @keyup="developmentsFilterKeyUp"
                  >
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-html="data.item.country ? data.item.country.title : ''"></span>
                            <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span v-html="data.item.city ? data.item.city.title : ''"></span>
                            <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                              fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span
                                v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                            <v-icon
                                v-if="data.item.parent_development"
                                x-small class="ml-1 mr-1">fas fa-angle-double-right
                            </v-icon>

                            <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                      clearable
                      outlined
                      dense
                      :items="propertyTypesFilter"
                      v-model="editedItem.property_type_id"
                      item-text="full_title"
                      item-value="id"
                      label="Property Type"
                      chips
                      small-chips
                      deletable-chips
                      :append-icon="'mdi-refresh'"
                      @click:append="getPropertyTypes(false)"
                  >
                    <template v-slot:append-outer>
                      <a target="_blank" href="/setup/property-types?addModule=true">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                          clearable
                          outlined
                          dense
                          :items="unitsFilter"
                          v-model="builtUpAreaUnit"
                          item-text="title"
                          item-value="value"
                          @change="clearChargesValues"
                          label="Unit"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="8"
                           v-if="builtUpAreaUnit !== 'Dimensions'"
                    >
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.built_up_area"
                                    :counter="10"
                                    maxLength="10"
                                    @keyup="clearChargesValues"
                                    @keypress="isNumber($event)"
                                    label="Built Up Area"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="builtUpAreaUnit && builtUpAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.built_up_area_width"
                                    :counter="10"
                                    maxLength="10"
                                    @keypress="isNumber($event)"
                                    label="Built Up Area Width"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="builtUpAreaUnit && builtUpAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.built_up_area_length"
                                    :counter="10"
                                    maxLength="10"
                                    @keypress="isNumber($event)"
                                    label="Built Up Area Length"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                          clearable
                          outlined
                          dense
                          :items="unitsFilter"
                          v-model="plotAreaUnit"
                          item-text="title"
                          item-value="value"
                          @change="clearChargesValues"
                          label="Unit"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="8" v-if="plotAreaUnit !== 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.plot_area"
                                    :counter="10"
                                    maxLength="10"
                                    @keyup="clearChargesValues"
                                    @keypress="isNumber($event)"
                                    label="Plot Area"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="plotAreaUnit && plotAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.plot_area_width"
                                    :counter="10"
                                    maxLength="10"
                                    @keypress="isNumber($event)"
                                    label="Plot Area Width"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="plotAreaUnit && plotAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.plot_area_length"
                                    :counter="10"
                                    maxLength="10"
                                    @keypress="isNumber($event)"
                                    label="Plot Area Length"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                          clearable
                          outlined
                          dense
                          :items="unitsFilter"
                          v-model="balconyAreaUnit"
                          item-text="title"
                          item-value="value"
                          label="Unit"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="8"
                           v-if="balconyAreaUnit !== 'Dimensions'"
                    >
                      <v-text-field clearable
                                    outlined
                                    dense v-model="editedItem.balcony_area"
                                    :counter="10"
                                    maxLength="10"
                                    @keypress="isNumber($event)"
                                    label="Balcony Area"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="balconyAreaUnit && balconyAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.balcony_area_width"
                                    :counter="10"
                                    maxLength="10"
                                    @keypress="isNumber($event)"
                                    label="Balcony Area Area Width"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="balconyAreaUnit && balconyAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.balcony_area_length"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Balcony Area Area Length"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                          clearable
                          outlined
                          dense
                          :items="unitsFilter"
                          v-model="terraceAreaUnit"
                          item-text="title"
                          item-value="value"
                          label="Unit"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="8" v-if="terraceAreaUnit !== 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.terrace_area"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Terrace Area"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="terraceAreaUnit && terraceAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.terrace_area_width"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Terrace Area Width"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="terraceAreaUnit && terraceAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.terrace_area_length"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Terrace Area Length"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                          clearable
                          outlined
                          dense
                          :items="unitsFilter"
                          v-model="commonAreaUnit"
                          item-text="title"
                          item-value="value"
                          label="Unit"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="8" v-if="commonAreaUnit !== 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.common_area"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Common Area"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="commonAreaUnit && commonAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.common_area_width"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Common Area Width"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4"
                           v-if="commonAreaUnit && commonAreaUnit === 'Dimensions'">
                      <v-text-field clearable
                                    outlined
                                    dense
                                    v-model="editedItem.common_area_length"
                                    :counter="10"
                                    @keypress="isNumber($event)"
                                    label="Common Area Length"></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.unit_no"
                                maxLength="30"
                                :counter="30"
                                label="Unit Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.floor"
                                maxLength="30"
                                :counter="30"
                                label="Floor"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.street_name_no"
                                maxLength="100"
                                :counter="100"
                                label="Street Name/Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.year_built"
                                maxLength="4"
                                :counter="4"
                                @keypress="isNumber($event)"
                                label="Year Built"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.parking_no"
                                maxLength="30"
                                :counter="30"
                                label="Parking Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.no_of_parking"
                                maxLength="30"
                                :counter="30"
                                label="Number Of Parking"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.land_no"
                                maxLength="50"
                                :counter="50"
                                label="Land Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.official_residence_no"
                                maxLength="50"
                                :counter="50"
                                label="Official Residence Number/Makani Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.electricity_meter_no"
                                maxLength="50"
                                :counter="50"
                                label="Electricity Meter Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.gas_meter_no"
                                maxLength="50"
                                :counter="50"
                                label="Gas Meter Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense v-model="editedItem.municipality_number"
                                maxLength="50"
                                :counter="50"
                                label="Municipality Number"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn
                      outlined
                      dense
                      color="#00004d"
                      dark
                      height="40"
                      @click="$store.state.selections.lrDialog = true"
                      style="width:100%"
                  >
                                  <span v-if="$store.state.selections.selectedLRIds.length > 0"
                                        v-html="$store.state.selections.selectedLRIds.length + ' Location Remark(s) Selected'"
                                  ></span>
                    <span v-else>Select Location Remarks</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                      v-model="editedItem.cooling_provider_ids"
                      :items="coolingProvidersFilter"
                      item-text="full_name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                      deletable-chips
                      chips
                      :loading="isCoolingProvidersLoaded"
                      small-chips
                      multiple
                      label="Monthly Cooling Provider(s) (min. 3 characters required)"
                      @keyup="coolingProvidersFilterKeyUp"
                  >
                    <template v-slot:append-outer>
                      <a target="_blank" href="/contacts-management/contacts?addModule=true">
                        <v-icon>mdi-plus-circle-outline</v-icon>
                      </a>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense
                                :counter="20"
                                @keypress="isNumber($event)"
                                @keyup="yscKeyUp"
                                v-model="yscValue"
                                label="Yearly Service Charges"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      clearable
                      outlined
                      dense
                      v-model="yscMultiplyValue"
                      :items="chargesMultiplies"
                      item-text="title"
                      item-value="value"
                      label="Built Up Area Sq Ft/Plot Size Sq Ft"
                      @change="yscMultiply"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense
                                v-model="that.$store.state.projectunitdetails.yearlyServiceCharges"
                                :counter="20"
                                @keypress="isNumber($event)"
                                label="Total"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense
                                :counter="20"
                                @keypress="isNumber($event)"
                                @keyup="mccKeyUp"
                                v-model="mccValue"
                                label="Monthly Cooling Charges"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-select
                      clearable
                      outlined
                      dense
                      v-model="mccMultiplyValue"
                      :items="chargesMultiplies"
                      item-text="title"
                      item-value="value"
                      label="Built Up Area Sq Ft/Plot Size Sq Ft"
                      @change="mccMultiply"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field clearable
                                outlined
                                dense
                                v-model="that.$store.state.projectunitdetails.monthlyCoolingCharges"
                                :counter="20"
                                @keypress="isNumber($event)"
                                label="Total"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
            <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
              Save
            </v-btn>
          </v-card-actions>
          <v-col cols="12">
            <div v-if="errors!=undefined && errors.length >= 0"
                 role="alert"
                 v-bind:class="{ show: errors.length }"
                 class="alert fade alert-danger"
            >
              <div class="alert-text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </div>
            </div>
          </v-col>
        </v-card>
      </v-dialog>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
              <div class="text-center font-weight-bold">
                Search/Filters
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form @submit.stop.prevent="hitAdvanceSearch">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                        v-model="searchFormData.developmentSearch"
                        :items="developmentsFilter"
                        item-text="commercial_title"
                        item-value="id"
                        outlined
                        dense
                        deletable-chips
                        chips
                        :loading="isDevelopmentsLoaded"
                        small-chips
                        hide-details
                        label="Development (min. 3 characters required)"
                        @keyup="developmentsFilterKeyUp"
                    >
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-html="data.item.country ? data.item.country.title : ''"></span>
                              <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span v-html="data.item.city ? data.item.city.title : ''"></span>
                              <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-autocomplete
                        v-model="searchFormData.projectSearch"
                        :items="projectsFilter"
                        item-text="title"
                        item-value="id"
                        dense
                        hide-details
                        outlined
                        chips
                        deletable-chips
                        :loading="isProjectsLoaded"
                        small-chips
                        label="Project (Minimum 3 Characters Required)"
                        @keyup="projectsFilterKeyUp"
                    >
                      <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                          <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-html="data.item.country ? data.item.country.title : ''"></span>
                              <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span v-html="data.item.city ? data.item.city.title : ''"></span>
                              <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                              <v-icon
                                  v-if="data.item.development && data.item.development.parent_development"
                                  x-small class="ml-1 mr-1">fas fa-angle-double-right
                              </v-icon>

                              <span
                                  v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                              <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>

                              <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <button :disabled="loading" type="submit" style="width: 100%"
                            class="btn btn-success theme-btn ml-auto mr-2">
                      Search
                      <v-icon class="ml-2" small>fas fa-search</v-icon>
                    </button>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <button :disabled="loading" style="width: 100%" @click="resetSearchForm"
                            class="btn btn-primary ml-auto">
                      Reset
                      <v-icon class="ml-2" small>fas fa-times</v-icon>
                    </button>
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-card-title>
        Properties ({{ addTSBVZ(totalRecords) }})
      </v-card-title>
      <!--        <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"-->
      <!--        >-->
      <!--        </v-skeleton-loader>-->
      <v-data-table
          :headers="headers"
          :items="items"
          dense
          show-expand
          fixed-header
          height="500"
          :options.sync="pagination"
          :server-items-length="totalRecords"
          :footer-props="{
                    itemsPerPageText:'Page no. ' + params.page + ' - Rows per page',
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
          :loading="loading"
          mobile-breakpoint="100"
          ref="mrc-contacts-properties-datatable"
          @update:page="datatableScrollToTop('mrc-contacts-properties-datatable')"
          class="elevation-0 custom-td-table custom-td-table-scroll">
        <template v-slot:item.projectcheck="{ item }">
          <div v-if="item.project">
            <span v-html="item.project.country ? item.project.country.title : ''"></span>
            <v-icon v-if="item.project.country" x-small class="ml-1 mr-1">fas
              fa-angle-double-right
            </v-icon>

            <span v-html="item.project.city ? item.project.city.title : ''"></span>
            <v-icon v-if="item.project.city" x-small class="ml-1 mr-1">fas
              fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development && item.project.development.parent_development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development) ? item.project.development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.project.development && item.project.development.parent_development && item.project.development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.project.development && item.project.development.parent_development) ? item.project.development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.project.development && item.project.development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="item.project.development ? item.project.development.commercial_title : ''"></span>
            <v-icon v-if="item.project.development" x-small class="ml-1 mr-1">fas
              fa-angle-double-right
            </v-icon>

            <span v-html="'<strong>' + item.project.title + '</strong>'"></span>
          </div>

          <div v-if="item.development">
            <span v-html="item.development.country ? item.development.country.title : ''"></span>
            <v-icon v-if="item.development.country" x-small class="ml-1 mr-1">fas
              fa-angle-double-right
            </v-icon>

            <span v-html="item.development.city ? item.development.city.title : ''"></span>
            <v-icon v-if="item.development.city" x-small class="ml-1 mr-1">fas
              fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development) ? item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.development.parent_development && item.development.parent_development.parent_development && item.development.parent_development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.development.parent_development && item.development.parent_development.parent_development) ? item.development.parent_development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.development.parent_development && item.development.parent_development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span
                v-html="(item.development.parent_development) ? item.development.parent_development.commercial_title : ''"></span>
            <v-icon
                v-if="item.development.parent_development"
                x-small class="ml-1 mr-1">fas fa-angle-double-right
            </v-icon>

            <span v-html="'<strong>' + item.development.commercial_title + '</strong>'"></span>
          </div>
        </template>
        <template v-slot:item.built_up_area_calc="{ item }">
          <div class="text-truncate">
            <span v-html="item.built_up_area ? item.built_up_area + ' ' : '-'"></span>
            <span v-if="item.built_up_area" v-html="item.built_up_area_unit"></span>
          </div>
        </template>
        <template v-slot:item.plot_area="{ item }">
          <div class="text-truncate">
            <span v-html="item.plot_area ? item.plot_area + ' ' : '-'"></span>
            <span v-if="item.plot_area" v-html="item.plot_area_unit"></span>
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div class="text-truncate">
            {{ formatDateAndTime(item.created_at) }} <br> by
            {{ item.created_user_name }}
          </div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div class="text-truncate">
            {{ formatDateAndTime(item.updated_at) }} <br> by
            {{ item.updated_user_name }}
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col cols="12">
                <v-card
                    class="mx-auto"
                    outlined
                    rounded
                >
                  <v-card-text class="pt-0 pb-0">
                    <v-row>
                      <v-col cols="12" sm="3">
                        <div>
                          <h6>Official Residence Number/Makani Number</h6>
                          <p v-html="item.official_residence_no ? item.official_residence_no : '-'"></p>
                        </div>
                        <div>
                          <h6>Electricity Meter Number</h6>
                          <p v-html="item.electricity_meter_no ? item.electricity_meter_no : '-'"></p>
                        </div>
                        <div>
                          <h6>Gas Meter Number</h6>
                          <p v-html="item.gas_meter_no ? item.gas_meter_no : '-'"></p>
                        </div>
                        <div>
                          <h6>Land Number</h6>
                          <p v-html="item.land_no ? item.land_no : '-'"></p>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <div>
                          <h6>Yearly Service Charges</h6>
                          <p v-html="item.yearly_service_charges ? item.yearly_service_charges : '-'"></p>
                        </div>
                        <div>
                          <h6>Monthly Cooling Charges</h6>
                          <p v-html="item.monthly_cooling_charges ? item.monthly_cooling_charges : '-'"></p>
                        </div>
                        <div>
                          <h6>Location Remarks</h6>
                          <p v-html="item.location_remarks_titles ? item.location_remarks_titles : '-'"></p>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <div>
                          <h6>Balcony Area</h6>
                          <p>
                            <span v-html="item.balcony_area ? item.balcony_area + ' ' : '-'"></span>
                            <span v-if="item.balcony_area" v-html="item.balcony_area_unit"></span></p>
                        </div>

                        <div>
                          <h6>Terrace Area</h6>
                          <p>
                            <span v-html="item.terrace_area ? item.terrace_area + ' ' : '-'"></span>
                            <span v-if="item.terrace_area" v-html="item.terrace_area_unit"></span></p>
                        </div>

                        <div>
                          <h6>Common Area</h6>
                          <p>
                            <span v-html="item.common_area ? item.common_area + ' ' : '-'"></span>
                            <span v-if="item.common_area" v-html="item.common_area_unit"></span></p>
                        </div>

                        <div>
                          <h6>Street Name/Number</h6>
                          <p v-html="item.street_name_no ? item.street_name_no : '-'"></p>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="3">
                        <div>
                          <h6>Year Built</h6>
                          <p v-html="item.year_built ? item.year_built : '-'"></p>
                        </div>
                        <div>
                          <h6>Parking Number</h6>
                          <p v-html="item.parking_no ? item.parking_no : '-'"></p>
                        </div>
                        <div>
                          <h6>Number of Parking</h6>
                          <p v-html="item.no_of_parking ? item.no_of_parking : '-'"></p>
                        </div>
                        <div>
                          <h6>Land Number</h6>
                          <p v-html="item.land_no ? item.land_no : '-'"></p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-truncate">
            <v-btn color="#00004d" small @click="showEditDialog(item)" dark class="mr-2">
              <v-icon dark x-small>fas fa-edit</v-icon>
            </v-btn>
            <!--                    <v-btn color="red" dark @click="deleteItem(item)" class="mr-2">-->
            <!--                      Delete-->
            <!--                      <v-icon dark right small>fas fa-trash</v-icon>-->
            <!--                    </v-btn>-->
          </div>
        </template>
      </v-data-table>
      <!-- this dialog is used for both create and update -->
    </v-card>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  CLEAR_CONTACT_ERRORS, CONTACTS_SIMPLE_LIST,
  GET_CONTACT_PROPERTIES
} from "@/core/services/store/contacts.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {LOCATIONREMARKS_SIMPLE_LIST} from "@/core/services/store/locationremarks.module";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";
import {DEVELOPMENTS_SIMPLE_LIST} from "@/core/services/store/developments.module";
import LocationRemarksSelection from "@/view/pages/realstate/selections/LocationRemarksSelection";
import {
  CREATE_PROJECTUNITDETAILS, GET_PROJECTUNITDETAILS_DETAILS,
  UPDATE_PROJECTUNITDETAILS
} from "@/core/services/store/projectunitdetails.module";
import {PURPOSES_SIMPLE_LIST} from "@/core/services/store/purposes.module";
import {FREQUENCIES_SIMPLE_LIST} from "@/core/services/store/frequencies.module";

export default {
  name: 'contact-properties',
  props: ['contactId'],
  components: {LocationRemarksSelection},
  data() {
    return {
      that: this,
      headers: [
        {
          text: "Project/Development",
          width: '30%',
          data: null,
          value: "projectcheck",
          name: "projectcheck",
          filterable: false,
          sortable: false
        },
        {text: "Unit Number", value: "unit_no", name: "unit_no", filterable: false, sortable: true},
        {text: 'Property Title', value: 'title', name: 'title', filterable: false, sortable: false},
        {text: 'Purpose', value: 'purpose.title', name: 'purpose.title', filterable: false, sortable: false},
        {text: 'Minimum Price', value: 'min_price', name: 'min_price', filterable: false, sortable: false},
        {text: 'Maximum Price', value: 'max_price', name: 'max_price', filterable: false, sortable: false},
        {text: 'Minimum Rent', value: 'min_rent', name: 'min_rent', filterable: false, sortable: false},
        {text: 'Maximum Rent', value: 'max_rent', name: 'max_rent', filterable: false, sortable: false},
        {text: 'Rent Frequency', value: 'rent_frequency.title', name: 'rent_frequency.title', filterable: false, sortable: false},
        {
          text: 'Property Type',
          value: 'property_type.title',
          name: 'property_type.title',
          filterable: false,
          sortable: false
        },
        {
          text: "Built Up Area SqMt",
          value: "built_up_area_calc.square_meter",
          name: "built_up_area_sqmt",
          filterable: false,
          sortable: false
        },
        {
          text: "Built Up Area SqFt",
          value: "built_up_area_calc.square_feet",
          name: "built_up_area_sqft",
          filterable: false,
          sortable: false
        },
        {
          text: "Plot Area SqMt",
          value: "plot_area_calc.square_meter",
          name: "plot_area_sqmt",
          filterable: false,
          sortable: false
        },
        {
          text: "Plot Area SqFt",
          value: "plot_area_calc.square_feet",
          name: "plot_area_sqft",
          filterable: false,
          sortable: false
        },
        {text: "Floor", value: "floor", name: "floor", filterable: false, sortable: true},
        {
          text: "Municipality Number",
          value: "municipality_number",
          name: "municipality_number",
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          align: ' d-none',
          value: "created_at",
          name: "created_at",
          filterable: false,
          sortable: false
        },
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      propertyTypesFilter: [],
      purposesFilter: [],
      locationRemarksFilter: [],
      locationRemarksIdsFilter: [],
      projectsFilter: [],
      developmentsFilter: [],
      isDevelopmentsLoaded: false,
      isProjectsLoaded: false,
      items: [],
      searchFormData: {},
      dialog: false,
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than 1 characters",
          v => (v && v.length <= 100) || "Must be less than 100 characters"
        ]
      },
      editedItem: {},
      yscValue: '',
      yscMultiplyValue: '',
      mccValue: '',
      mccMultiplyValue: '',
      chargesMultiplies: [
        {value: "0", title: 'Built up area sq ft'},
        {value: "1", title: 'Plot size sq ft'},
      ],
      contactsFilter: [],
      isContactsLoaded: false,
      builtUpAreaUnit: '',
      plotAreaUnit: '',
      balconyAreaUnit: '',
      terraceAreaUnit: '',
      commonAreaUnit: '',
      coolingProvidersFilter: [],
      rfFilter: [],
      isCoolingProvidersLoaded: false,
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    contactId(v) {
      this.items = []
      this.pagination.page = 1
      this.params.itemsPerPage = 25
      this.pagination.rowsPerPage = 25
      if (v) {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      } else {
        this.searchFormData = {}
      }
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.$store.state.projectunitdetails.yearlyServiceCharges = '';
      if (!val) this.$store.state.projectunitdetails.monthlyCoolingCharges = '';
      if (!val) this.$store.state.selections.selectedLRIds = []
      if (val) {
        this.builtUpAreaUnit = this.builtUpAreaUnit ? this.builtUpAreaUnit : this.$store.state.auth.user.measurement_unit
        this.plotAreaUnit = this.plotAreaUnit ? this.plotAreaUnit : this.$store.state.auth.user.measurement_unit
        this.balconyAreaUnit = this.balconyAreaUnit ? this.balconyAreaUnit : this.$store.state.auth.user.measurement_unit
        this.terraceAreaUnit = this.terraceAreaUnit ? this.terraceAreaUnit : this.$store.state.auth.user.measurement_unit
        this.commonAreaUnit = this.commonAreaUnit ? this.commonAreaUnit : this.$store.state.auth.user.measurement_unit
      } else {
        this.builtUpAreaUnit = ''
        this.plotAreaUnit = ''
        this.balconyAreaUnit = ''
        this.terraceAreaUnit = ''
        this.commonAreaUnit = ''
      }
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.getPropertyTypes();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    hitAdvanceSearch() {
      this.pagination.page = 1
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    resetSearchForm() {
      this.pagination.page = 1
      this.searchFormData = {};
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_PROJECTUNITDETAILS_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        if (data.records.project) {
          let proArr = [];
          proArr.push(data.records.project)
          this.projectsFilter = proArr;
        }
        if (data.records.development) {
          let devArr = [];
          devArr.push(data.records.development)
          this.developmentsFilter = devArr;
        }
        this.builtUpAreaUnit = data.records.built_up_area_unit
        this.plotAreaUnit = data.records.plot_area_unit
        this.balconyAreaUnit = data.records.balcony_area_unit
        this.terraceAreaUnit = data.records.terrace_area_unit
        this.commonAreaUnit = data.records.common_area_unit
        this.editedItem.min_price = this.addTSBV(data.records.min_price)
        this.editedItem.min_rent = this.addTSBV(data.records.min_rent)
        this.editedItem.max_price = this.addTSBV(data.records.max_price)
        this.editedItem.max_rent = this.addTSBV(data.records.max_rent)
        this.$store.state.projectunitdetails.yearlyServiceCharges = data.records.yearly_service_charges
        this.$store.state.projectunitdetails.monthlyCoolingCharges = data.records.monthly_cooling_charges
        this.coolingProvidersFilter = data.records.cooling_providers;
        this.$store.state.selections.selectedLRIds = this.editedItem.location_remark_ids
        this.dialog = !this.dialog;
      });
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_PROJECTUNITDETAILS;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "contact_id", this.contactId);
        this.$set(item, "owner_ids", [this.contactId]);
        this.$set(item, "yearly_service_charges", this.$store.state.projectunitdetails.yearlyServiceCharges ? this.$store.state.projectunitdetails.yearlyServiceCharges.toFixed(3) : '');
        this.$set(item, "monthly_cooling_charges", this.$store.state.projectunitdetails.monthlyCoolingCharges ? this.$store.state.projectunitdetails.monthlyCoolingCharges.toFixed(3) : '');
        this.$set(item, "built_up_area_unit", this.builtUpAreaUnit);
        this.$set(item, "plot_area_unit", this.plotAreaUnit);
        this.$set(item, "balcony_area_unit", this.balconyAreaUnit);
        this.$set(item, "terrace_area_unit", this.terraceAreaUnit);
        this.$set(item, "common_area_unit", this.commonAreaUnit);
        this.$set(item, "location_remark_ids", this.$store.state.selections.selectedLRIds);
        item.min_price = item.min_price ? item.min_price.replace(/,(?=\d{3})/g, '') : ''
        item.min_rent = item.min_rent ? item.min_rent.replace(/,(?=\d{3})/g, '') : ''
        item.max_price = item.max_price ? item.max_price.replace(/,(?=\d{3})/g, '') : ''
        item.max_rent = item.max_rent ? item.max_rent.replace(/,(?=\d{3})/g, '') : ''
        if (id) {
          method = UPDATE_PROJECTUNITDETAILS;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_CONTACT_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {}
          this.dialog = !this.dialog
        })
      }
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        if (searchFormDataQuery) query += '&' + searchFormDataQuery;
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(GET_CONTACT_PROPERTIES, {
          contact_id: this.contactId,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getPropertyTypes();
      });
    },
    getPropertyTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'Properties', is_property: 1}).then(data => {
        this.propertyTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getPurposes();
      });
    },
    getPurposes(loadNext = true) {
      this.$store.dispatch(PURPOSES_SIMPLE_LIST).then((data) => {
        this.purposesFilter = data.records.purposesList;
        if (loadNext) this.getRentFrequencies()
      });
    },
    getRentFrequencies(loadNext = true) {
      this.$store.dispatch(FREQUENCIES_SIMPLE_LIST, {type: 'Full Term'}).then((data) => {
        this.rfFilter = data.records.frequenciesList;
        if (loadNext) this.getLocationRemarks()
      });
    },
    getLocationRemarks() {
      this.$store.dispatch(LOCATIONREMARKS_SIMPLE_LIST, {type: 'Projects'}).then(data => {
        this.locationRemarksFilter = data.records.locationRemarksList;
        let that = this;
        this.locationRemarksFilter.forEach(function (v) {
          that.locationRemarksIdsFilter.push(v.id)
        })
      });
    },
    projectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isProjectsLoaded = true;
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: 'none'
              })
              .then(data => {
                that.projectsFilter = data.records.projectsList;
                that.isProjectsLoaded = false;
              });
        }
      }, 900);
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopmentsLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                q: e.target.value
              })
              .then(data => {
                that.developmentsFilter = data.records.developmentsList;
                that.isDevelopmentsLoaded = false;
              });
        }
      }, 900);
    },
    coolingProvidersFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isCoolingProvidersLoaded = true;
        this.$store
            .dispatch(CONTACTS_SIMPLE_LIST, {
              role: 'Cooling Providers',
              q: e.target.value
            })
            .then(data => {
              this.coolingProvidersFilter = data.records.contactsList;
              this.isCoolingProvidersLoaded = false;
            });
      }
    },
    yscKeyUp(evt) {
      if (!evt.target.value) {
        this.$store.state.projectunitdetails.yearlyServiceCharges = ''
      }
      if (
          evt.target.value &&
          this.editedItem.built_up_area &&
          this.editedItem.built_up_area_unit &&
          this.yscMultiplyValue &&
          this.yscMultiplyValue === "0"
      ) {
        this.$store.state.projectunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.built_up_area_unit, evt.target.value, this.editedItem.built_up_area)
      }

      if (
          evt.target.value &&
          this.editedItem.plot_area &&
          this.editedItem.plot_area_unit &&
          this.yscMultiplyValue &&
          this.yscMultiplyValue === "1"
      ) {
        this.$store.state.projectunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.plot_area_unit, evt.target.value, this.editedItem.plot_area)
      }
    },
    yscMultiply(v) {
      if (!v) {
        this.$store.state.projectunitdetails.yearlyServiceCharges = ''
      }
      if (v === '0') {
        if (
            this.yscValue &&
            this.editedItem.built_up_area &&
            this.editedItem.built_up_area_unit
        ) {
          this.$store.state.projectunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.built_up_area_unit, this.yscValue, this.editedItem.built_up_area);
        }
      }
      if (v === '1') {
        if (
            this.yscValue &&
            this.editedItem.plot_area &&
            this.editedItem.plot_area_unit
        ) {
          this.$store.state.projectunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.plot_area_unit, this.yscValue, this.editedItem.plot_area);
        }
      }
    },
    mccKeyUp(evt) {
      if (!evt.target.value) {
        this.$store.state.projectunitdetails.monthlyCoolingCharges = ''
      }
      if (
          evt.target.value &&
          this.editedItem.built_up_area &&
          this.editedItem.built_up_area_unit &&
          this.mccMultiplyValue &&
          this.mccMultiplyValue === "0"
      ) {
        this.$store.state.projectunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.built_up_area_unit, evt.target.value, this.editedItem.built_up_area)
      }

      if (
          evt.target.value &&
          this.editedItem.plot_area &&
          this.editedItem.plot_area_unit &&
          this.mccMultiplyValue &&
          this.mccMultiplyValue === "1"
      ) {
        this.$store.state.projectunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.plot_area_unit, evt.target.value, this.editedItem.plot_area)
      }
    },
    mccMultiply(v) {
      if (!v) {
        this.$store.state.projectunitdetails.monthlyCoolingCharges = ''
      }
      if (v === '0') {
        if (
            this.mccValue &&
            this.editedItem.built_up_area &&
            this.editedItem.built_up_area_unit
        ) {
          this.$store.state.projectunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.built_up_area_unit, this.mccValue, this.editedItem.built_up_area);
        }
      }
      if (v === '1') {
        if (
            this.mccValue &&
            this.editedItem.plot_area &&
            this.editedItem.plot_area_unit
        ) {
          this.$store.state.projectunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.plot_area_unit, this.mccValue, this.editedItem.plot_area);
        }
      }
    },
    clearChargesValues() {
      this.yscValue = '';
      this.mccValue = '';
      this.$store.state.projectunitdetails.monthlyCoolingCharges = ''
      this.$store.state.projectunitdetails.yearlyServiceCharges = ''
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.contacts.errors
    })
  }
};
</script>
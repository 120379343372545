<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeViewDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0 hidden-sm-and-down">
        <span v-html="item.full_name"></span>
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs class="custom-scroll" v-model="activeTabDetail" background-color="#002366" left dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Details</v-tab>
      <v-tab>Contact Details</v-tab>
      <v-tab>Bank Details</v-tab>
      <v-tab>Card Details</v-tab>
      <v-tab>Social Media Handler</v-tab>
      <v-tab>Media</v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="activeTabDetail">
      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <h6>Type of Contact:</h6>
              <p v-if="item.type_of_contact === '0'">Individual</p>
              <p v-else-if="item.type_of_contact === '1'">Institution/Company/Organization</p>
              <p v-else>-</p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Salutation:</h6>
              <p v-html="item.salutation ? item.salutation.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Father/Husband Name:</h6>
              <p v-html="item.father_husband_name ? item.father_husband_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Date Of Birth:</h6>
              <p v-html="item.dob ? formatMomentDate(item.dob) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Gender:</h6>
              <p v-if="item.gender === '0'">Male</p>
              <p v-else-if="item.gender === '1'">Female</p>
              <p v-else>-</p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Parent/Head Office/Main Branch:</h6>
              <p v-html="item.parent ? item.parent.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Source:</h6>
              <p v-html="item.source ? item.source.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Domain/Category/Sector:</h6>
              <p v-html="item.contact_domain_titles ? item.contact_domain_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Role/Group:</h6>
              <p v-html="item.contact_role_titles ? item.contact_role_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Occupation/Profession:</h6>
              <p v-html="item.contact_occupation_titles ? item.contact_occupation_titles : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Trade License Number Issuing Authority:</h6>
              <p v-html="item.tln_authority ? item.tln_authority.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Trade Licence Number:</h6>
              <p v-html="item.trade_license_number ? item.trade_license_number : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Organization Registration Number Issuing Authority:</h6>
              <p v-html="item.orn_authority ? item.orn_authority.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Organization Registration Number:</h6>
              <p v-html="item.organization_registration_number ? item.organization_registration_number : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Broker Registration Number Issuing Authority:</h6>
              <p v-html="item.brn_authority ? item.brn_authority.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Broker Registration Number:</h6>
              <p v-html="item.broker_registration_number ? item.broker_registration_number : '-'"></p>
            </v-col>

            <v-col cols="12" sm="3">
              <h6>Developer Registration Number Issuing Authority:</h6>
              <p v-html="item.drn_authority ? item.drn_authority.full_name : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Developer Registration Number:</h6>
              <p v-html="item.developer_registration_number ? item.developer_registration_number : '-'"></p>
            </v-col>
            <v-col cols="12" sm="12">
              <h6>Notes:</h6>
              <p v-html="item.notes ? item.notes : '-'"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="item.hasOwnProperty('contact_emails') && item.contact_emails.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Emails:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_emails" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_phones') && item.contact_phones.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Phones:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_phones" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_addresses') && item.contact_addresses.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Addresses:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_addresses" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p class="mb-1" v-html="link.title"></p>
                    <p class="mb-1" v-if="link.country" v-html="link.country ? link.country.title : ''"></p>
                    <p class="mb-1" v-if="link.city" v-html="link.city ? link.city.title : ''"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_passport_numbers') && item.contact_passport_numbers.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Passport Number:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_passport_numbers" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.country ? link.country.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_national_ids') && item.contact_national_ids.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>National/Resident's Identification:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_national_ids" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_events') && item.contact_events.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Events:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_events" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p class="mb-1" v-html="link.title"></p>
                    <p v-html="link.date ? formatMomentDate(link.date) : ''"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_websites') && item.contact_websites.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Websites:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_websites" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_relationships') && item.contact_relationships.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Relationships:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_relationships" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_chats') && item.contact_chats.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Chats:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_chats" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('contact_internet_calls') && item.contact_internet_calls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Internet Calls:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.contact_internet_calls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.label ? link.label.title : ''"></p>
                    <p v-html="link.title"></p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat
                v-if="item.hasOwnProperty('contact_bank_details') && item.contact_bank_details.length > 0"
        >
          <v-card-text v-for="(link, index) in item.contact_bank_details" :key="index">
            <v-row>
              <v-col cols="12" sm="3">
                <h6>Bank Name:</h6>
                <p v-html="link.name"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Branch Name:</h6>
                <p v-html="link.branch_name"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Branch Code:</h6>
                <p v-html="link.branch_code"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Swift Code:</h6>
                <p v-html="link.swift_code"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Address:</h6>
                <p v-html="link.location"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Account Number:</h6>
                <p v-html="link.account_no"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>IBAN Number:</h6>
                <p v-html="link.iban_no"></p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat
                v-if="item.hasOwnProperty('contact_card_details') && item.contact_card_details.length > 0"
        >
          <v-card-text v-for="(card, index) in item.contact_card_details" :key="index">
            <v-row>
              <v-col cols="12" sm="3">
                <h6>Debit/Credit Card Issuer:</h6>
                <p v-html="card.card ? card.card.title : '-'"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Card Number:</h6>
                <p v-html="card.card_number"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>Expiry Date:</h6>
                <p v-html="formatMomentDate(card.expiry_date)"></p>
              </v-col>
              <v-col cols="12" sm="3">
                <h6>CSV:</h6>
                <p v-html="card.csv"></p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="item.hasOwnProperty('youtube_videos_links') && item.youtube_videos_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Videos:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_videos_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('youtube_playlist_links') && item.youtube_playlist_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Playlist:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_playlist_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('virtual_tour_links') && item.virtual_tour_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Virtual Tour:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.virtual_tour_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('website_urls') && item.website_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Website URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.website_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('instagram_urls') && item.instagram_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Instagram URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.instagram_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('facebook_urls') && item.facebook_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Facebook URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.facebook_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('twitter_urls') && item.twitter_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Twitter URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.twitter_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('linkedin_urls') && item.linkedin_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Linkedin URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.linkedin_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-tabs
            v-model="activeTabDetailInner"
            background-color="primary" left dark>
          <v-tab>Logo/Photo</v-tab>
          <v-tab>Gallery</v-tab>
          <v-tab>Private Documents</v-tab>

          <v-tab-item>
            <v-img v-if="item.hasOwnProperty('image_public_url') && item.image_public_url"
                   :src="file.image_public_url"></v-img>
          </v-tab-item>
          <v-tab-item>
            <Gallery v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0" :items="item.gallery_files"/>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('private_document_files') && item.private_document_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.private_document_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ProjectUnitDetails from "@/view/pages/realstate/projects/inc/ProjectUnitDetails";
import ProjectUnitDetailsPm from "@/view/pages/realstate/projects/inc/ProjectUnitDetailsPm";
import ProjectTypicalFloorPlans from "@/view/pages/realstate/projects/inc/ProjectTypicalFloorPlans";
import ProjectInternalLayouts from "@/view/pages/realstate/projects/inc/ProjectInternalLayouts";
import ProjectExternalLayouts from "@/view/pages/realstate/projects/inc/ProjectExternalLayouts";
import ProjectFullTransactions from "@/view/pages/realstate/projects/inc/ProjectFullTransactions";
import ProjectSummary from "@/view/pages/realstate/projects/inc/ProjectSummary";
import Gallery from "@/view/pages/realstate/media/Gallery";

export default {
  name: 'view-contact',
  components: {
    Gallery,
    ProjectSummary,
    ProjectFullTransactions,
    ProjectUnitDetails,
    ProjectUnitDetailsPm,
    ProjectTypicalFloorPlans,
    ProjectInternalLayouts,
    ProjectExternalLayouts
  },
  props: ['item'],
  data() {
    return {
      center: {},
      locationMarkers: [],
    }
  },
  watch: {
    item() {
      this.activeTabDetail = 0
      this.activeTabDetailInner = 0
      this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
      this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
      const marker = {
        lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
        lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
      };
      this.locationMarkers.push({position: marker});
      this.center = marker;
    }
  },
  mounted() {
    this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
    this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
    const marker = {
      lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
      lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
    };
    this.locationMarkers.push({position: marker});
    this.center = marker;
    if (this.$route.query.hasOwnProperty('t')) {
      this.activeTabDetail = 14
    }
  },
  methods: {},
  computed: {}
};
</script>
<template>
  <div class="comments-section">
    <v-row>
      <v-col cols="12">
        <div class="form-group" v-for="(comment,k) in comments" :key="k" style="margin: 0">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-card rounded outlined raised
                      v-bind="attrs"
                      v-on="on"
              >
                <!--            <v-card-title v-html="comment.created_user_name ? comment.created_user_name : '-&#45;&#45;'"></v-card-title>-->
                <!--            <v-card-subtitle v-html="formatDateAndTime(comment.created_at)"></v-card-subtitle>-->
                <v-card-text>
                  <div class="text--primary font-weight-bold">
                    Date and Time: {{formatDateAndTime(comment.start_date)}}
                  </div>
                  <div class="text--primary" v-html="replaceNewLine(comment.text)"></div>
                </v-card-text>
              </v-card>
              <br>
            </template>
            <span
                v-html="comment.created_user_name ? comment.created_user_name + ' at ' + formatDateAndTime(comment.created_at) : '-'"></span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="contact.reminder_count !== comments.length">
      <v-col cols="12">
        <a
            href="javascript:void(0)"
           :style="commentLoading ? 'pointer-events: none' : 'pointer-events: all'" @click="getReminders">Show previous reminders</a>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="pt-0">
        <v-textarea
            outlined
            clearable
            dense
            hide-details
            label="Reminder Text"
            color="primary"
            v-model="text"
            maxlength="500"
            :counter="500">
        </v-textarea>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-text-field
            type="date"
            outlined
            clearable
            dense
            hide-details
            label="Date"
            :min="$store.state.auth.c_d"
            color="primary"
            v-model="startDate">
        </v-text-field>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-text-field
            :disabled="!startDate"
            type="time"
            outlined
            clearable
            dense
            hide-details
            label="Time"
            color="primary"
            v-model="startTime">
        </v-text-field>
      </v-col>
<!--      <v-col cols="12" class="pt-0">-->
<!--        <v-text-field-->
<!--            :disabled="!startTime"-->
<!--            type="date"-->
<!--            outlined-->
<!--            clearable-->
<!--            dense-->
<!--            hide-details-->
<!--            :min="startDate"-->
<!--            label="End Date"-->
<!--            color="primary"-->
<!--            v-model="endDate">-->
<!--        </v-text-field>-->
<!--      </v-col>-->
<!--      <v-col cols="12" class="pt-0">-->
<!--        <v-text-field-->
<!--            :disabled="!endDate"-->
<!--            type="time"-->
<!--            outlined-->
<!--            clearable-->
<!--            dense-->
<!--            hide-details-->
<!--            label="End Time"-->
<!--            color="primary"-->
<!--            v-model="endTime">-->
<!--        </v-text-field>-->
<!--      </v-col>-->
      <v-col cols="12" class="pt-0">
        <v-btn :disabled="!text || !startDate || !startTime" @click="saveComment" class="theme-btn" dark style="width: 100%">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  CLEAR_CONTACT_ERRORS,
  GET_REMINDER,
  SAVE_REMINDER
} from "@/core/services/store/contacts.module";

export default {
  name: 'contact-reminders',
  props: ['contact','contactRemindersOld'],
  data() {
    return {
      page: 1,
      text: '',
      startDate: '',
      startTime: '',
      comments: [],
      commentLoading: false
    }
  },
  watch: {
    contactRemindersOld(v) {
      this.comments = v;
    }
  },
  mounted() {
    this.comments = this.contactRemindersOld
  },
  methods: {
    saveComment() {
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      this.$store.dispatch(SAVE_REMINDER, {
        'contact_id': this.contact.id,
        text: this.text,
        startDate: this.startDate,
        startTime: this.startTime,
      }).then((res) => {
        this.$emit('reminderSaved', res.records);
        this.text = ''
        this.startDate = ''
        this.startTime = ''
        this.page = 1;
      })
    },
    getReminders() {
      this.commentLoading = true
      this.$store.dispatch(GET_REMINDER, {'contact_id': this.contact.id, page: this.page}).then((res) => {
        let that = this;
        if (res.records.data.length > 0){
          if (this.page === 1) {
            this.comments = res.records.data;
          } else {
            res.records.data.forEach(function (v){
              that.comments.push(v)
            })
          }
          this.page++
          this.commentLoading = false
        }
        // this.$emit('commentSaved');
      })
    }
  }
}
</script>
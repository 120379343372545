<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <v-dialog persistent v-model="propsDialog" fullscreen hide-overlay>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="editedItem = {}; propsDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-html="editedItem.full_name + '\'s Properties'"></span>
                    </v-toolbar-title>
                  </v-toolbar>
                  <ProjectUnitDetails
                      pudType="contact"
                      :listingId="cListingId"
                      :contact="editedItem"
                      project=""
                  ></ProjectUnitDetails>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="shareDialog" max-width="1000px">
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="shareDialog = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Share Your Contacts</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="contactSharingIds.length === 0 && contactSharingVendorIds.length === 0"
                          dark
                          text
                          @click="shareContacts"
                      >
                        Share
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-select
                              item-text="title"
                              item-value="value"
                              clearable
                              outlined
                              dense
                              hide-details
                              :disabled="$store.state.auth.user.type === 0"
                              v-model="contactSharingWith"
                              :items="sharingFilters"
                              label="Share With"
                              @change="
                                contactSharingIds = [];
                                contactSharingVendorIds = [];
                                contactProjectIds = [];
                                contactCountyIds = [];
                                contactDevelopmentIds = [];
                                contactSharingType = '';
                              "
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="contactSharingWith">
                          <v-select
                              item-text="title"
                              item-value="value"
                              clearable
                              outlined
                              dense
                              hide-details
                              v-model="contactSharingType"
                              :items="sharingTypesFilters"
                              label="Share Contacts"
                              @change="
                                contactSharingIds = [];
                                contactSharingVendorIds = [];
                                contactProjectIds = [];
                                contactCountyIds = [];
                                contactDevelopmentIds = [];
                              "
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12"
                               v-if="contactSharingWith && contactSharingType && contactSharingType === 1">
                          <v-autocomplete
                              v-model="contactCountyIds"
                              :items="countryFilter"
                              item-text="full_title"
                              item-value="id"
                              clearable
                              outlined
                              multiple
                              dense
                              chips
                              hide-details
                              small-chips
                              deletable-chips
                              label="Countries"
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12"
                               v-if="contactSharingWith && contactSharingType && contactSharingType === 2">
                          <v-autocomplete
                              v-model="contactDevelopmentIds"
                              :items="developmentsFilter"
                              item-text="commercial_title"
                              item-value="id"
                              clearable
                              outlined
                              multiple
                              dense
                              deletable-chips
                              chips
                              :loading="isDevelopmentsLoaded"
                              small-chips
                              hide-details
                              label="Development (min. 3 characters required)"
                              @keyup="developmentsFilterKeyUp"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12"
                               v-if="contactSharingWith && contactSharingType && contactSharingType === 3">
                          <v-autocomplete
                              v-model="contactProjectIds"
                              :items="projectsFilter"
                              item-text="title"
                              item-value="id"
                              no-filter
                              dense
                              multiple
                              hide-details
                              clearable
                              outlined
                              chips
                              :loading="isProjectsLoaded"
                              small-chips
                              label="Project (Minimum 3 Characters Required)"
                              @keyup="projectsFilterKeyUp"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development"
                                        x-small class="ml-1 mr-1">fas fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                                    <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="
                          (contactSharingWith === 1 && contactSharingType === 4) ||
                          (contactSharingWith === 1 && contactCountyIds.length > 0) ||
                          (contactSharingWith === 1 && contactDevelopmentIds.length > 0) ||
                          (contactSharingWith === 1 && contactProjectIds.length > 0)
                        ">
                          <v-autocomplete
                              v-model="contactSharingIds"
                              :items="staffFilter"
                              item-text="name"
                              item-value="id"
                              clearable
                              outlined
                              dense
                              chips
                              small-chips
                              multiple
                              deletable-chips
                              label="Share With*"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title
                                    v-html="data.item.name + ' (' + data.item.email + ')'">
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" v-if="
                          (contactSharingWith === 2 && contactSharingType === 4) ||
                          (contactSharingWith === 2 && contactCountyIds.length > 0) ||
                          (contactSharingWith === 2 && contactDevelopmentIds.length > 0) ||
                          (contactSharingWith === 2 && contactProjectIds.length > 0)
                        ">
                          <v-autocomplete
                              v-model="contactSharingVendorIds"
                              :items="vendorsFilter"
                              item-text="name"
                              item-value="id"
                              clearable
                              outlined
                              dense
                              chips
                              small-chips
                              multiple
                              deletable-chips
                              label="Share With*"
                          >
                            <template v-slot:item="data">
                              <v-list-item-content>
                                <v-list-item-title
                                    v-html="data.item.name + ' (' + data.item.email + ')'">
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                  <SharedContactsUsers :doReload="shareDialog"/>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialog" fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn :disabled="loading" color="#00004d" class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn v-if="hasPermission('contacts.create')" color="#00004d" dark class="ma-3 ml-0"
                           v-on="on">
                      Add
                      <v-icon class="ml-2" small>fas fa-plus-circle</v-icon>
                    </v-btn>
                    <!--                    <v-btn v-if="staffFilter.length > 1 && items.length > 0" color="#00004d" dark class="ma-3 ml-0"-->
                    <!--                           @click="showShareDialog">-->
                    <!--                      Share Contacts-->
                    <!--                      <v-icon class="ml-2" small>fas fa-share-alt</v-icon>-->
                    <!--                    </v-btn>-->
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit "{{ editedItem.full_name }}"</span>
                      <span v-else>Create Contact</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-tabs v-model="activeTab" background-color="#002366" left dark>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Basic Details</v-tab>
                    <v-tab>Contact Details</v-tab>
                    <v-tab>Bank Details</v-tab>
                    <v-tab>Card Details</v-tab>
                    <v-tab>Social Media Handler</v-tab>
                    <v-tab>Media</v-tab>
                  </v-tabs>
                  <v-tabs-items touchless v-model="activeTab">
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form
                              class="custom_form"
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                          >
                            <v-row>
                              <v-col cols="12" sm="4">
                                <v-select
                                    clearable
                                    outlined
                                    dense
                                    v-model="editedItem.type_of_contact"
                                    :items="typeOfContact"
                                    item-text="title"
                                    item-value="value"
                                    :rules="validationRules.typeOfContactRules"
                                    @change="typeOfContactChange"
                                    label="Type of Contact*">
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="contactTypeIsInd">
                                <v-autocomplete
                                    v-model="editedItem.salutation_id"
                                    :items="salutationFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Salutation"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getSalutations(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/salutations?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="nameLabel">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.full_name" :counter="300"
                                              :rules="validationRules.firstNameRules"
                                              :label="nameLabel + '*'"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="contactTypeIsInd">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.father_husband_name" :counter="300"
                                              label="Father/Husband Name"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="contactTypeIsInd">
                                <v-text-field type="date" clearable
                                              outlined
                                              dense
                                              v-model="editedItem.dob"
                                              label="Date Of Birth"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4" v-if="contactTypeIsInd">
                                <v-select clearable
                                          outlined
                                          dense v-model="editedItem.gender" :items="genders"
                                          item-text="title"
                                          item-value="value"
                                          label="Gender" required>
                                </v-select>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.parent_id"
                                    :items="contactsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isContactsLoaded"
                                    small-chips
                                    label="Parent/Head Office/Main Branch (min. 3 characters required)"
                                    @keyup="contactsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.source_id"
                                    :items="sourcesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    deletable-chips
                                    label="Source"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getSources(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/setup/sources?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.contact_domain_ids"
                                    :items="domainsFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    label="Domain/Category/Sector"
                                    deletable-chips
                                    multiple
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getDomains(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/domains?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.contact_role_ids"
                                    :items="contactRolesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    label="Role/Group*"
                                    deletable-chips
                                    multiple
                                    required
                                    :rules="validationRules.contactRoleRules"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getContactRoles(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contact-roles?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.contact_occupation_ids"
                                    :items="occupationsFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    chips
                                    small-chips
                                    label="Occupation/Profession"
                                    deletable-chips
                                    multiple
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getOccupations(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/occupations?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_contact === '1'">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.tln_authority_id"
                                    :items="tlnAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isTlnAuthorityLoaded"
                                    small-chips
                                    label="Trade License Number Issuing Authority (min. 3 characters required)"
                                    @keyup="tlnAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.trade_license_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Trade Licence Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_contact === '1'">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.orn_authority_id"
                                    :items="ornAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isOrnAuthorityLoaded"
                                    small-chips
                                    label="Organization Registration Number Issuing Authority (min. 3 characters required)"
                                    @keyup="ornAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.organization_registration_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Organization Registration Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_contact">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.brn_authority_id"
                                    :items="brnAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isBrnAuthorityLoaded"
                                    small-chips
                                    label="Broker Registration Number Issuing Authority (min. 3 characters required)"
                                    @keyup="brnAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.broker_registration_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Broker Registration Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row v-if="editedItem.type_of_contact === '1'">
                              <v-col cols="12" sm="6">
                                <v-autocomplete
                                    v-model="editedItem.drn_authority_id"
                                    :items="drnAuthorityFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isDrnAuthorityLoaded"
                                    small-chips
                                    label="Developer Registration Number Issuing Authority (min. 3 characters required)"
                                    @keyup="drnAuthorityFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field v-model="editedItem.developer_registration_number"
                                              clearable
                                              outlined
                                              dense
                                              :counter="50"
                                              label="Developer Registration Number"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-textarea outlined
                                            dense clearable
                                            v-model="editedItem.notes" :counter="500"
                                            label="Notes">

                                </v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Email"
                                moduleLabel="Emails"
                                moduleFor="contact_emails"
                                :contactDetailsOld="editedItem.contact_emails ? editedItem.contact_emails : editedItem.contact_emails === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Phone"
                                moduleLabel="Phones"
                                moduleFor="contact_phones"
                                :contactDetailsOld="editedItem.contact_phones ? editedItem.contact_phones : editedItem.contact_phones === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Address"
                                moduleLabel="Addresses"
                                moduleFor="contact_addresses"
                                :contactDetailsOld="editedItem.contact_addresses ? editedItem.contact_addresses : editedItem.contact_addresses === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Passport Number"
                                moduleLabel="Passport Number"
                                moduleFor="contact_passport_numbers"
                                :contactDetailsOld="editedItem.contact_passport_numbers ? editedItem.contact_passport_numbers : editedItem.contact_passport_numbers === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="National/Resident's Identification"
                                moduleLabel="National/Resident's Identification"
                                moduleFor="contact_national_ids"
                                :contactDetailsOld="editedItem.contact_national_ids ? editedItem.contact_national_ids : editedItem.contact_national_ids === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Event"
                                moduleLabel="Events"
                                moduleFor="contact_events"
                                :contactDetailsOld="editedItem.contact_events ? editedItem.contact_events : editedItem.contact_events === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Website"
                                moduleLabel="Websites"
                                moduleFor="contact_websites"
                                :contactDetailsOld="editedItem.contact_websites ? editedItem.contact_websites : editedItem.contact_websites === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Relationship"
                                moduleLabel="Relationships"
                                moduleFor="contact_relationships"
                                :contactDetailsOld="editedItem.contact_relationships ? editedItem.contact_relationships : editedItem.contact_relationships === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Chat"
                                moduleLabel="Chats"
                                moduleFor="contact_chats"
                                :contactDetailsOld="editedItem.contact_chats ? editedItem.contact_chats : editedItem.contact_chats === []"
                            ></ContactDetails>
                            <ContactDetails
                                moduleOf="contact_details"
                                moduleTitle="Internet Call"
                                moduleLabel="Internet Calls"
                                moduleFor="contact_internet_calls"
                                :contactDetailsOld="editedItem.contact_internet_calls ? editedItem.contact_internet_calls : editedItem.contact_internet_calls === []"
                            ></ContactDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ContactBankDetails
                                moduleOf="contact_bank_details"
                                moduleTitle="Bank Details"
                                :contactBankDetailsOld="editedItem.contact_bank_details ? editedItem.contact_bank_details : editedItem.contact_bank_details === []"
                            ></ContactBankDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ContactCardDetails
                                moduleOf="contact_card_details"
                                moduleTitle="Card Details"
                                :contactCardDetailsOld="editedItem.contact_card_details ? editedItem.contact_card_details : editedItem.contact_card_details === []"
                            ></ContactCardDetails>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="youtube_videos_links"
                                moduleTitle="Youtube Videos"
                                :moduleLinksOld="editedItem.youtube_videos_links ? editedItem.youtube_videos_links : editedItem.youtube_videos_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="youtube_playlist_links"
                                moduleTitle="Youtube Playlist"
                                :moduleLinksOld="editedItem.youtube_playlist_links ? editedItem.youtube_playlist_links : editedItem.youtube_playlist_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="virtual_tour_links"
                                moduleTitle="Virtual Tour"
                                :moduleLinksOld="editedItem.virtual_tour_links ? editedItem.virtual_tour_links : editedItem.virtual_tour_links === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="instagram_urls"
                                moduleTitle="Instagram URLS"
                                :moduleLinksOld="editedItem.instagram_urls ? editedItem.instagram_urls : editedItem.instagram_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="facebook_urls"
                                moduleTitle="Facebook URLS"
                                :moduleLinksOld="editedItem.facebook_urls ? editedItem.facebook_urls : editedItem.facebook_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="twitter_urls"
                                moduleTitle="Twitter URLS"
                                :moduleLinksOld="editedItem.twitter_urls ? editedItem.twitter_urls : editedItem.twitter_urls === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="contact_links"
                                moduleFor="linkedin_urls"
                                moduleTitle="Linkedin URLS"
                                :moduleLinksOld="editedItem.linkedin_urls ? editedItem.linkedin_urls : editedItem.linkedin_urls === []"
                            ></ModuleLinks>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-tabs
                          v-model="activeTabInner"
                          background-color="primary" left dark>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab>Logo/Photo</v-tab>
                        <v-tab>Gallery</v-tab>
                        <v-tab>Private Documents</v-tab>
                      </v-tabs>
                      <v-tabs-items touchless v-model="activeTabInner">
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="main"
                              moduleOf="contacts"
                              moduleTitle="Logo/Photo"
                              acceptedFiles="image/jpeg, image/png"
                              :moduleMultiple="false"
                              :deleteModule="false"
                              :moduleFilesOld="editedItem.image_public_url ? [editedItem] : []"
                          ></ModuleMedia>
                        </v-tab-item>
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="contact_files"
                              moduleFor="contact_gallery"
                              moduleTitle="Gallery"
                              acceptedFiles="image/jpeg, image/png"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="contacts"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="contact_files"
                              moduleKey="contact_id"
                              moduleTitle="Gallery"
                              moduleOfFolder="contacts"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="contact_files"
                              moduleFor="private_documents"
                              moduleTitle="Private Documents"
                              acceptedFiles="image/jpeg, image/png, application/pdf"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="contacts"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="contact_files"
                              moduleKey="contact_id"
                              moduleTitle="Private Documents"
                              moduleOfFolder="contacts"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="smsDialog" fullscreen>
                <SendSms @closeSmsDlg="smsDialog = false; vFN = []" :phoneNumbers="vFN"/>
              </v-dialog>
              <v-dialog persistent v-model="emailDialog" fullscreen>
                <SendEmail @closeEmailDlg="emailDialog = false; vEmails = []; emailMsg = ''"
                           :contactEmails="vEmails"
                           :sharedListings="sharedListings"
                           :emailMsg="emailMsg"
                />
              </v-dialog>
              <v-dialog persistent v-model="viewDialog" fullscreen>
                <v-card>
                  <ViewContact :item="viewItem" @closeViewDialog="viewDialog = false"/>
                </v-card>
              </v-dialog>
              <v-divider class="m-0"></v-divider>
              <v-col cols="12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters/Locations
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form v-on:submit.prevent="hitAdvanceSearch">
                        <v-row>
                          <v-col cols="12" sm="4">
                            <v-select
                                clearable
                                dense
                                outlined
                                hide-details
                                v-model="searchFormData.typeOfContactSearch"
                                :items="typeOfContact"
                                item-text="title"
                                item-value="value"
                                label="Type of Contact">
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.parentSearch"
                                :items="parentFilter"
                                item-text="full_name"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                deletable-chips
                                :loading="isParentLoaded"
                                hide-details
                                label="Parent/Head Office/Main Branch (min. 3 characters required)"
                                @keyup="parentsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <h6 v-html="data.item.full_name"></h6>
                                      <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                      <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                      <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="300"
                                          outlined
                                          dense
                                          hide-details
                                          maxlength="300" v-model="searchFormData.fullNameSearch"
                                          label="Name"></v-text-field>
                            <!--                              <v-autocomplete-->
                            <!--                                  v-model="searchFormData.fullNameSearch"-->
                            <!--                                  :items="contactsFilter"-->
                            <!--                                  item-text="full_name"-->
                            <!--                                  item-value="id"-->
                            <!--                                  clearable-->
                            <!--                                  outlined-->
                            <!--                                  dense-->
                            <!--                                  deletable-chips-->
                            <!--                                  chips-->
                            <!--                                  :loading="isContactsLoaded"-->
                            <!--                                  hide-details-->
                            <!--                                  dense-->
                            <!--                                  small-chips-->
                            <!--                                  label="Full Name (min. 3 characters required)"-->
                            <!--                                  @keyup="contactsFilterKeyUp"-->
                            <!--                              >-->
                            <!--                              </v-autocomplete>-->
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="100"
                                          outlined
                                          dense
                                          hide-details
                                          maxlength="100" v-model="searchFormData.emailSearch"
                                          label="Email"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="100"
                                          outlined
                                          dense
                                          hide-details
                                          maxlength="100" v-model="searchFormData.phoneSearch"
                                          label="Phone"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.location_id"
                                :items="locationsFilter"
                                item-text="text"
                                item-value="id"
                                no-filter
                                clearable
                                outlined
                                dense
                                return-object
                                deletable-chips
                                chips
                                :loading="isLocationsLoaded"
                                small-chips
                                hide-details
                                label="Location (min. 3 characters required)"
                                @keyup="locationsFilterKeyUp"
                                @change="locationChange($event)"
                            >
                            </v-autocomplete>
                          </v-col>
                          <!--                          <v-col cols="12" sm="4">-->
                          <!--                            <v-autocomplete-->
                          <!--                                v-model="searchFormData.developmentSearch"-->
                          <!--                                :items="developmentsFilter"-->
                          <!--                                item-text="commercial_title"-->
                          <!--                                item-value="id"-->
                          <!--                                clearable-->
                          <!--                                outlined-->
                          <!--                                dense-->
                          <!--                                deletable-chips-->
                          <!--                                chips-->
                          <!--                                :loading="isDevelopmentsLoaded"-->
                          <!--                                small-chips-->
                          <!--                                hide-details-->
                          <!--                                label="Development (min. 3 characters required)"-->
                          <!--                                @keyup="developmentsFilterKeyUp"-->
                          <!--                            >-->
                          <!--                              <template v-slot:item="data">-->
                          <!--                                <template v-if="typeof data.item !== 'object'">-->
                          <!--                                  <v-list-item-content v-text="data.item"></v-list-item-content>-->
                          <!--                                </template>-->
                          <!--                                <template v-else>-->
                          <!--                                  <v-list-item-content>-->
                          <!--                                    <v-list-item-title>-->
                          <!--                                      <span v-html="data.item.country ? data.item.country.title : ''"></span>-->
                          <!--                                      <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas-->
                          <!--                                        fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span v-html="data.item.city ? data.item.city.title : ''"></span>-->
                          <!--                                      <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas-->
                          <!--                                        fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span-->
                          <!--                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>-->
                          <!--                                      <v-icon-->
                          <!--                                          v-if="data.item.parent_development && data.item.parent_development.parent_development"-->
                          <!--                                          x-small class="ml-1 mr-1">fas fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span-->
                          <!--                                          v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>-->
                          <!--                                      <v-icon-->
                          <!--                                          v-if="data.item.parent_development"-->
                          <!--                                          x-small class="ml-1 mr-1">fas fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>-->
                          <!--                                    </v-list-item-title>-->
                          <!--                                  </v-list-item-content>-->
                          <!--                                </template>-->
                          <!--                              </template>-->
                          <!--                            </v-autocomplete>-->
                          <!--                          </v-col>-->
                          <!--                          <v-col cols="12" sm="4">-->
                          <!--                            <v-autocomplete-->
                          <!--                                v-model="searchFormData.projectSearch"-->
                          <!--                                :items="projectsFilter"-->
                          <!--                                item-text="title"-->
                          <!--                                item-value="id"-->
                          <!--                                no-filter-->
                          <!--                                dense-->
                          <!--                                hide-details-->
                          <!--                                clearable-->
                          <!--                                outlined-->
                          <!--                                chips-->
                          <!--                                :loading="isProjectsLoaded"-->
                          <!--                                small-chips-->
                          <!--                                label="Project (Minimum 3 Characters Required)"-->
                          <!--                                @keyup="projectsFilterKeyUp"-->
                          <!--                            >-->
                          <!--                              <template v-slot:item="data">-->
                          <!--                                <template v-if="typeof data.item !== 'object'">-->
                          <!--                                  <v-list-item-content v-text="data.item"></v-list-item-content>-->
                          <!--                                </template>-->
                          <!--                                <template v-else>-->
                          <!--                                  <v-list-item-content>-->
                          <!--                                    <v-list-item-title>-->
                          <!--                                      <span v-html="data.item.country ? data.item.country.title : ''"></span>-->
                          <!--                                      <v-icon v-if="data.item.country" x-small class="ml-1 mr-1">fas-->
                          <!--                                        fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span v-html="data.item.city ? data.item.city.title : ''"></span>-->
                          <!--                                      <v-icon v-if="data.item.city" x-small class="ml-1 mr-1">fas-->
                          <!--                                        fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span-->
                          <!--                                          v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>-->
                          <!--                                      <v-icon-->
                          <!--                                          v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"-->
                          <!--                                          x-small class="ml-1 mr-1">fas fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span-->
                          <!--                                          v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>-->
                          <!--                                      <v-icon-->
                          <!--                                          v-if="data.item.development && data.item.development.parent_development"-->
                          <!--                                          x-small class="ml-1 mr-1">fas fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span-->
                          <!--                                          v-html="data.item.development ? data.item.development.commercial_title : ''"></span>-->
                          <!--                                      <v-icon v-if="data.item.development" x-small class="ml-1 mr-1">fas-->
                          <!--                                        fa-angle-double-right-->
                          <!--                                      </v-icon>-->

                          <!--                                      <span v-html="'<strong>' + data.item.title + '</strong>'"></span>-->
                          <!--                                    </v-list-item-title>-->
                          <!--                                  </v-list-item-content>-->
                          <!--                                </template>-->
                          <!--                              </template>-->
                          <!--                            </v-autocomplete>-->
                          <!--                          </v-col>-->
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.countrySearch"
                                :items="countryFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                hide-details
                                dense
                                chips
                                small-chips
                                label="Nationality"
                                deletable-chips
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.roleSearch"
                                :items="contactRolesFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                hide-details
                                dense
                                chips
                                small-chips
                                label="Role/Group"
                                deletable-chips
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.domainSearch"
                                :items="domainsFilter"
                                item-text="title"
                                item-value="id"
                                outlined
                                hide-details
                                dense
                                clearable
                                chips
                                small-chips
                                label="Domain/Sector/Category"
                                deletable-chips
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.sourceSearch"
                                :items="sourcesFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                hide-details
                                dense
                                chips
                                small-chips
                                label="Source"
                                deletable-chips
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.ptSearch"
                                :items="ptFilter"
                                item-text="full_title"
                                item-value="id"
                                dense
                                outlined
                                deletable-chips
                                chips
                                small-chips
                                label="Property Type"
                                hide-details
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.ctSearch"
                                :items="cTFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                hide-details
                                dense
                                chips
                                small-chips
                                label="Comment Title"
                                deletable-chips
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                dense outlined
                                clearable
                                hide-details
                                :items="bedsFilter"
                                v-model="searchFormData.bedSearch"
                                item-text="title"
                                item-value="value"
                                label="Beds"
                            ></v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          dense outlined
                                          hide-details
                                          maxlength="10" v-model="searchFormData.minBuaSearch"
                                          label="Min Built Up Area"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          dense outlined
                                          hide-details
                                          maxlength="10" v-model="searchFormData.maxBuaSearch"
                                          label="Max Built Up Area"></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          dense outlined
                                          hide-details
                                          maxlength="10" v-model="searchFormData.minPaSearch"
                                          label="Min Plot Area"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          dense outlined
                                          hide-details
                                          maxlength="10" v-model="searchFormData.maxPaSearch"
                                          label="Max Plot Area"></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.tenancySearch"
                                :items="tenancyFilter"
                                item-text="title"
                                item-value="value"
                                dense
                                outlined
                                deletable-chips
                                chips
                                small-chips
                                label="Rent Status"
                                hide-details
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select
                                dense outlined
                                hide-details
                                clearable
                                @change="drChange"
                                :items="dateRangeFilter"
                                v-model="searchFormData.drSearch"
                                item-text="title"
                                item-value="value"
                                label="Contacted Date Range"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field type="date" dense outlined hide-details
                                          v-model="searchFormData.cdfSearch"
                                          label="Contacted Date From"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field type="date" dense outlined hide-details v-model="searchFormData.cdtSearch"
                                          label="Contacted Date To"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.abSearch"
                                :items="staffFilter"
                                item-text="name"
                                item-value="id"
                                hide-details
                                clearable
                                outlined
                                dense
                                chips
                                small-chips
                                deletable-chips
                                label="Contact Added By"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title
                                      v-html="data.item.name + ' (' + data.item.email + ')'">
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-autocomplete
                                v-model="searchFormData.cabSearch"
                                :items="staffFilter"
                                item-text="name"
                                item-value="id"
                                clearable
                                outlined
                                hide-details
                                dense
                                chips
                                small-chips
                                deletable-chips
                                label="Comments Added By"
                            >
                              <template v-slot:item="data">
                                <v-list-item-content>
                                  <v-list-item-title
                                      v-html="data.item.name + ' (' + data.item.email + ')'">
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12" sm="4">
                            <v-select
                                v-model="searchFormData.sortSearch"
                                :items="sortFilter"
                                item-text="title"
                                item-value="value"
                                clearable
                                outlined
                                hide-details
                                dense
                                label="Sort By"
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Search
                              <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" style="width: 100%" @click="resetSearchForm(true)"
                                    class="btn btn-primary ml-auto">
                              Reset
                              <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-divider class="m-0"></v-divider>
                      <v-row
                          v-if="treeItems.length > 0">
                        <v-col cols="12">
                          <v-card-title class="pl-0 pr-0">
                            <v-icon
                                :class="treeLoading ? 'no-events' : ''"
                                v-if="treeItemsPrev.length > 1"
                                color="primary"
                                @click="showTree(treeItemsPrev[1].id, treeItemsPrev[1].title, treeItemsPrev[1].type, 'back')"
                                left small>fas fa-arrow-left
                            </v-icon>
                            Locations
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="searchQueryTree"
                                append-icon="search"
                                label="Search"
                                single-line
                                hide-details
                            ></v-text-field>
                          </v-card-title>
                        </v-col>
                        <v-row :class="showClass">
                          <v-col cols="12" sm="3" v-for="(treeItem, idx) in filteredResources" :key="idx">
                            <a :class="treeLoading ? 'no-events' : ''" style="font-size: 15px;color: blue"
                               @click="showTree(treeItem.id, treeItem.title, treeItem.type, 'go', treeItem)">
                              {{ treeItem.title }} ({{ treeItem.count }})
                            </a>
                          </v-col>
                        </v-row>
                        <v-col cols="12" sm="12" class="text-center">
                          <a :class="treeLoading ? 'no-events' : ''" v-if="filteredResources.length > 12 && showMore"
                             @click="showClass = 'mrc-show-more'; showLess = true; showMore = false;"
                             style="font-size: 12px;color: blue">
                            Show More
                          </a>
                          <a :class="treeLoading ? 'no-events' : ''" v-if="showLess"
                             @click="showClass = 'mrc-show-less'; showLess = false; showMore = true;"
                             style="font-size: 12px;color: blue">
                            Show Less
                          </a>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-card-title ref="scrollTo">
                Contacts ({{ addTSBVZ(totalRecords) }})
              </v-card-title>
              <v-row>
                <v-col cols="12" class="hidden-sm-and-down">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
                <v-col cols="12" class="hidden-md-and-up">
                  <v-pagination
                      color="#00004d"
                      :total-visible="3"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"></v-skeleton-loader>
              <v-toolbar
                  v-if="mainDataTableSelection.length > 0" class="mrc-fixed-bar"
                  height="50"
                  dark
                  color="#00004d">
                <v-toolbar-items>
                  <v-checkbox class="mt-3" v-if="items.length > 0"
                              :indeterminate="(items.length > mainDataTableSelection.length) && (mainDataTableSelection.length > 0)"
                              :value="items.length === mainDataTableSelection.length"
                              @change="bulkSelectChange($event, items)"></v-checkbox>
                  <v-btn
                      v-if="$store.state.auth.user.type === 1"
                      dark
                      text
                      @click="verifyNumbers"
                  >
                    Send SMS
                  </v-btn>
                  <v-btn
                      dark
                      text
                      @click="verifyEmails"
                  >
                    Send Email
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text v-if="!isLoaded">
                <v-row v-if="items.length > 0">
                  <v-col cols="12" v-for="(item, index) in items" :key="index">
                    <v-card
                        class="mx-auto"
                        outlined
                        raised
                    >
                      <v-toolbar
                          height="40"
                          dark
                          color="#00004d"
                      >
                        <!--                        <v-toolbar-title class="mr-1">-->
                        <!--                          <span> {{ index + 1 }} - </span>-->
                        <!--                        </v-toolbar-title>-->
                        <v-checkbox class="mt-5"
                                    :true-value="1"
                                    :false-value="0"
                                    :value="item"
                                    v-model="mainDataTableSelection"></v-checkbox>
                        <v-toolbar-title class="hidden-sm-and-down">
                            <span
                                v-if="item.type_of_contact && item.type_of_contact === '0'"
                                v-html="item.salutation ? item.salutation.title : ''">
                          </span> <span v-html="item.full_name"></span>
                          <span class="font-weight-bold" v-if="item.no_of_properties > 1"
                                v-html="' (' + item.no_of_properties + ' Properties)'"></span>
                          <span class="font-weight-bold" v-if="item.no_of_properties === 1"
                                v-html="' (' + item.no_of_properties + ' Property)'"></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items class="hidden-sm-and-down">
                          <v-btn
                              dark
                              text
                              @click="editedItem = item; propsDialog = true"
                          >
                            View Properties
                          </v-btn>
                          <v-btn
                              dark
                              text
                              @click="showEditDialog('detail', item)"
                          >
                            View Details
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('contacts.update')"
                              dark
                              text
                              @click="showEditDialog('edit', item)"
                          >
                            Edit
                          </v-btn>
                          <v-btn
                              @click="deleteItem(item)"
                              v-if="hasPermission('contacts.archive')"
                              dark
                              text
                          >
                            Archive
                          </v-btn>
                        </v-toolbar-items>
                        <v-menu
                            bottom
                            :close-on-content-click="true"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="hidden-md-and-up"
                                height="25"
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              Actions
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-btn
                                  text
                                  @click="editedItem = item; propsDialog = true"
                              >
                                View Properties
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn
                                  text
                                  @click="showEditDialog('detail', item)"
                              >
                                View Details
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('contacts.update')">
                              <v-btn
                                  text
                                  @click="showEditDialog('edit', item)"
                              >
                                Edit
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                @click="deleteItem(item)"
                                v-if="hasPermission('contacts.archive')">
                              <v-btn
                                  text
                              >
                                Archive
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                      <v-card-text>
                        <v-expansion-panels class="hidden-md-and-up" accordion v-model="pwaPanel">
                          <v-expansion-panel>
                            <v-expansion-panel-header class="custom-expansion-panel-header" color="primary">
                              <div class="text-center font-weight-bold">
                                Comments
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <ContactComments
                                  @commentSaved="refreshComments($event, item)"
                                  @commentsReloaded="refreshComments($event, item, true)"
                                  @viewListingFromComment="viewListingFromComment"
                                  :contact="item"
                                  :commentTitles="cTFilter"
                                  :contactCommentsOld="item.contact_comments ? item.contact_comments : item.contact_comments === []"
                              ></ContactComments>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-expansion-panels class="hidden-md-and-up mt-2 mb-2" accordion>
                          <v-expansion-panel>
                            <v-expansion-panel-header class="custom-expansion-panel-header" color="primary">
                              <div class="text-center font-weight-bold">
                                Reminders
                              </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <ContactReminders
                                  @reminderSaved="refreshReminders($event, item)"
                                  :contact="item"
                                  :contactRemindersOld="item.contact_reminders ? item.contact_reminders : item.contact_reminders === []"
                              ></ContactReminders>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>

                        <v-row>
                          <v-col cols="12" sm="3">
                            <h6 class="hidden-md-and-up">Name</h6>
                            <p class="hidden-md-and-up">
                              <span
                                  v-if="item.type_of_contact && item.type_of_contact === '0'"
                                  v-html="item.salutation ? item.salutation.title : ''">
                               </span> <span v-html="item.full_name"></span>
                              <span class="font-weight-bold" v-if="item.no_of_properties > 1"
                                    v-html="' (' + item.no_of_properties + ' Properties)'"></span>
                              <span class="font-weight-bold" v-if="item.no_of_properties === 1"
                                    v-html="' (' + item.no_of_properties + ' Property)'"></span>
                            </p>

                            <div v-if="item.type_of_contact === '0'">
                              <h6>Gender</h6>
                              <p v-if="item.gender === '0'">Male</p>
                              <p v-else-if="item.gender === '1'">Female</p>
                              <p v-else>-</p>
                            </div>

                            <h6>Email</h6>
                            <p v-if="item.c_emails" v-html="replaceCommaLine(item.c_emails)"></p>
                            <p v-else>-</p>

                            <h6>Phone</h6>
                            <p v-if="item.c_phones" v-html="replaceCommaLine(item.c_phones)"></p>
                            <p v-else>-</p>

                            <h6>Passport Number</h6>
                            <p v-if="item.passport_numbers" v-html="replaceCommaLine(item.passport_numbers)"></p>
                            <p v-else>-</p>

                            <h6>Added By</h6>
                            <p v-html="item.created_user_name ? item.created_user_name + ' at ' + formatDateAndTime(item.created_at) : '-'"></p>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <div v-if="item.type_of_contact === '0'">
                              <h6>Date Of Birth</h6>
                              <p v-if="item.dob" v-html="item.dob"></p>
                              <p v-else>-</p>
                            </div>

                            <h6>Nationality</h6>
                            <p v-if="item.nationalities" v-html="replaceCommaLine(item.nationalities)"></p>
                            <p v-else>-</p>

                            <h6>National/Resident's Identification</h6>
                            <p v-if="item.national_ids" v-html="replaceCommaLine(item.national_ids)"></p>
                            <p v-else>-</p>

                            <h6>Source</h6>
                            <p v-html="item.source ? item.source : '-'"></p>

<!--                            <h6>Website</h6>-->
<!--                            <p v-if="item.websites" v-html="replaceCommaLine(item.websites)"></p>-->
<!--                            <p v-else>-</p>-->
                          </v-col>

                          <v-col class="hidden-sm-and-down" cols="12" sm="3">
                            <ContactComments
                                @commentSaved="refreshComments($event, item, false)"
                                @commentsReloaded="refreshComments($event, item, true)"
                                @viewListingFromComment="viewListingFromComment"
                                :contact="item"
                                :commentTitles="cTFilter"
                                :contactCommentsOld="item.contact_comments ? item.contact_comments : item.contact_comments === []"
                            ></ContactComments>
                          </v-col>

                          <v-col class="hidden-sm-and-down" cols="12" sm="3">
                            <h6>Reminders</h6>
                            <ContactReminders
                                @reminderSaved="refreshReminders($event, item)"
                                :contact="item"
                                :contactRemindersOld="item.contact_reminders ? item.contact_reminders : item.contact_reminders === []"
                            ></ContactReminders>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!--                            <v-toolbar-->
                      <!--                                height="30"-->
                      <!--                                dark-->
                      <!--                                color="primary"-->
                      <!--                            >-->
                      <!--                              <v-toolbar-title>-->
                      <!--                                Created at:-->
                      <!--                                {{ formatDateAndTime(item.created_at) }} by-->
                      <!--                                {{ item.created_user_name }}-->
                      <!--                              </v-toolbar-title>-->
                      <!--                              <v-spacer></v-spacer>-->
                      <!--                              <v-toolbar-title>-->
                      <!--                                Updated at:-->
                      <!--                                {{ formatDateAndTime(item.updated_at) }} by-->
                      <!--                                {{ item.updated_user_name }}-->
                      <!--                              </v-toolbar-title>-->
                      <!--                            </v-toolbar>-->
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <p class="text-center"><i>No Record Found.</i></p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  ref="mrc-contacts-datatable"
                  @update:page="datatableScrollToTop('mrc-contacts-datatable')"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0 custom-datatable"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer
                      class="custom-datatable-footer2"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="rowsPerPageItems"
                      :show-first-last-page="true"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="12" class="hidden-sm-and-down">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
                <v-col cols="12" class="hidden-md-and-up">
                  <v-pagination
                      color="#00004d"
                      :total-visible="3"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </div>
      </div>
    </div>
    <!--    <div class="mt-5" v-else><h1>Under Maintenance</h1></div>-->
  </div>
</template>

<script>
import {mapState} from "vuex";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import ModuleLinks from "@/view/pages/realstate/media/ModuleLinks";
import SendSms from "@/view/pages/realstate/contacts_management/inc/SendSms";
import SendEmail from "@/view/pages/realstate/contacts_management/inc/SendEmail";
import SharedContacts from "@/view/pages/realstate/contacts_management/inc/SharedContacts";
import ContactDetails from "@/view/pages/realstate/contacts_management/inc/ContactDetails";
import ContactBankDetails from "@/view/pages/realstate/contacts_management/inc/ContactBankDetails";
import ContactCardDetails from "@/view/pages/realstate/contacts_management/inc/ContactCardDetails";
import ContactComments from "@/view/pages/realstate/contacts_management/inc/ContactComments";
import ContactReminders from "@/view/pages/realstate/contacts_management/inc/ContactReminders";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_CONTACT_ERRORS,
  CONTACTS_LIST,
  CONTACTS_SIMPLE_LIST,
  CREATE_CONTACT,
  DELETE_CONTACT,
  GET_CONTACT_DETAILS,
  SHARE_CONTACTS,
  UPDATE_CONTACT, VERIFY_EMAILS, VERIFY_NUMBERS
} from "@/core/services/store/contacts.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {bus} from "@/main";
import {SOURCES_SIMPLE_LIST} from "@/core/services/store/sources.module";
import {CONTACTROLES_SIMPLE_LIST} from "@/core/services/store/contact_roles.module";
import {OCCUPATIONS_SIMPLE_LIST} from "@/core/services/store/occupations.module";
import {DOMAINS_SIMPLE_LIST} from "@/core/services/store/domains.module";
import {SALUTATIONS_SIMPLE_LIST} from "@/core/services/store/salutations.module";
import {STAFF_LIST} from "@/core/services/store/users.module";
import {PROJECTS_SIMPLE_LIST} from "@/core/services/store/projects.module";
import {COUNTRIES_SIMPLE_LIST} from "@/core/services/store/countries.module";
import {COMMENTTITLES_SIMPLE_LIST} from "@/core/services/store/commenttitles.module";
import {
  DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST,
  DEVELOPMENTS_SIMPLE_LIST,
  DEVELOPMENTS_WITH_COUNT
} from "@/core/services/store/developments.module";
import SharedContactsUsers from "@/view/pages/realstate/contacts_management/inc/SharedContactsUsers";
import Swal from "sweetalert2";
import ProjectUnitDetails from "@/view/pages/realstate/projects/inc/ProjectUnitDetails";
import ViewContact from "@/view/pages/realstate/contacts_management/inc/ViewContact";
import {LISTINGS_SIMPLE_BY_IDS_LIST} from "@/core/services/store/listings.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";

export default {
  name: 'contacts-backup',
  components: {
    ViewContact,
    ProjectUnitDetails,
    SharedContactsUsers,
    ModuleMedia,
    ModuleLinks,
    ContactDetails,
    SharedContacts,
    ContactBankDetails,
    ContactCardDetails,
    ContactComments,
    ContactReminders,
    SendSms,
    SendEmail,
    ModuleUploadedFiles
  },
  data() {
    return {
      // uM: 0,
      pwaPanel: 0,
      draw: 1,
      searchQuery: "",
      searchQueryTree: "",
      loading: true,
      isLoaded: true,
      treeLoading: true,
      pagination: {
        page: 1,
        rowsPerPage: 15,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [15, 25, 50],
      headerindex: "",
      columns: [],
      showUploadedFiles: false,
      headers: [
        {
          text: 'Name',
          value: 'full_name',
          data: 'full_name',
          name: 'full_name',
          filterable: false,
          sortable: false
        },
        {
          text: "Created",
          value: "comment_updated_at",
          name: "comment_updated_at",
          filterable: false,
          sortable: false
        },
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      validationRules: {
        valid: true,
        salutationRules: [
          v => !!v || "This field is required"
        ],
        firstNameRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 300) || "Must be less than 300 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        lastNameRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 300) || "Must be less than 300 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        primaryEmailRules: [
          v => !!v || "This field  is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        secondaryEmailRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 100) || "Must be less than 100 characters",
          v => /.+@.+\..+/.test(v) || "E-mail must be valid"
        ],
        nationalIdRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        passportNumberRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        externalRefNumberRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        dobRules: [
          v => !!v || "This field is required"
        ],
        poBoxRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        addressRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 300) || "Must be less than 300 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        landLineRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        mobileRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        alterPhoneNumberRules: [
          v => !!v || "This field is required",
          v => (v && v.length <= 50) || "Must be less than 50 characters",
          v => (v && v.length >= 3) || "Must be at least 3 characters"
        ],
        typeOfContactRules: [
          v => !!v || "This field is required"
        ],
        genderRules: [
          v => !!v || "This field is required"
        ],
        countryRules: [
          v => !!v || "This field is required"
        ],
        sourceRules: [
          v => !!v || "This field is required"
        ],
        contactRoleRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Role(s)';
            else return true;
          }
        ],
      },
      searchFormData: {
        location_id: '',
        location_ref: '',
        contactIdSearch: '',
        parentSearch: '',
        typeOfContactSearch: '',
        fullNameSearch: '',
        emailSearch: '',
        phoneSearch: '',
        countrySearch: '',
        sourceSearch: '',
        ptSearch: '',
        ctSearch: '',
        occupationSearch: [],
        domainSearch: '',
        bedSearch: '',
        tenancySearch: '',
        abSearch: '',
        cabSearch: '',
        roleSearch: '',
        commentSortSearch: '',
        cdfSearch: '',
        cdtSearch: '',
        drSearch: '',
        sortSearch: ''
      },
      items: [],
      nameLabel: '',
      contactTypeIsInd: false,
      contactsFilter: [],
      isContactsLoaded: false,
      parentFilter: [],
      isParentLoaded: false,
      sourcesFilter: [],
      salutationFilter: [],
      cTFilter: [],
      staffFilter: [],
      vendorsFilter: [],
      contactSharingIds: [],
      contactSharingVendorIds: [],
      contactCountyIds: [],
      contactDevelopmentIds: [],
      contactProjectIds: [],
      contactSharingWith: this.$store.state.auth.user.type === 0 ? 1 : '',
      contactSharingType: '',
      contactRolesFilter: [],
      countryFilter: [],
      occupationsFilter: [],
      domainsFilter: [],
      ptFilter: [],
      tlnAuthorityFilter: [],
      isTlnAuthorityLoaded: false,
      ornAuthorityFilter: [],
      isOrnAuthorityLoaded: false,
      brnAuthorityFilter: [],
      isBrnAuthorityLoaded: false,
      drnAuthorityFilter: [],
      isDrnAuthorityLoaded: false,
      developmentsFilter: [],
      isDevelopmentsLoaded: false,
      projectsFilter: [],
      isProjectsLoaded: false,
      locationsFilter: [],
      isLocationsLoaded: false,
      dialog: false,
      shareDialog: false,
      propsDialog: false,
      smsDialog: false,
      vFN: [],
      emailDialog: false,
      emailMsg: '',
      vEmails: [],
      editedItem: {},
      cListingId: '',
      typeOfContact: [
        {value: "0", title: 'Individual'},
        {value: "1", title: 'Institution/Company/Organization'}
      ],
      genders: [
        {value: "0", title: 'Male'},
        {value: "1", title: 'Female'}
      ],
      sortFilter: [
        {value: '1', title: 'Created'},
        {value: '2', title: 'High To Low Number Of Properties'},
        {value: '3', title: 'Low To High Number Of Properties'},
        {value: '4', title: 'Sale Price High To Low'},
        {value: '5', title: 'Sale Price Low To High'},
        {value: '6', title: 'Rent High To Low'},
        {value: '7', title: 'Rent Low To High'},
        {value: '8', title: 'Contacts With Last Comment Updated'}
      ],
      commentSortFilter: [
        {value: '1', title: 'Contacts With Last Comment Updated'},
        {value: '2', title: 'Contacts Without Comments'},
        {value: '3', title: 'Contacts With Your Comments'},
        {value: '4', title: 'Contacts Without Your Comments'}
      ],
      advSHitted: false,
      treeItems: [],
      viewDialog: false,
      viewItem: {},
      sharedListings: [],
    }
  },
  watch: {
    // params: {
    //   handler() {
    //     this.loadData().then(response => {
    //       this.items = response.items;
    //       this.totalRecords = response.total;
    //     });
    //   },
    //   deep: true
    // },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.showUploadedFiles = false;
      if (!val) this.nameLabel = '';
      if (!val) this.activeTab = 0;
      if (!val) this.contactTypeIsInd = false;
      if (!val) bus.$emit("moduleFilesDeletedByModal");
      if (!val) bus.$emit("moduleLinksDeletedByModal");
      if (!val) bus.$emit("contactDetailsDeletedByModal");
      if (!val) bus.$emit("contactBankDetailsDeletedByModal");
    },
    propsDialog(val) {
      if (!val) this.cListingId = '';
    },
    shareDialog(val) {
      if (val) {
        const index = this.staffFilter.findIndex(element => {
          if (element.id === this.$store.state.auth.user.id) {
            return true;
          }
        });
        if (index !== -1) this.staffFilter.splice(index, 1);
      }
      if (!val) this.contactSharingWith = this.$store.state.auth.user.type === 0 ? 1 : '';
      if (!val) this.contactSharingType = '';
      if (!val) this.contactProjectIds = [];
      if (!val) this.contactDevelopmentIds = [];
      if (!val) this.contactCountyIds = [];
      if (!val) this.contactSharingIds = [];
      if (!val) this.contactSharingVendorIds = [];
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Contact's Management", route: "contacts"},
      {title: "Contacts"}
    ]);
    if (this.$route.query.hasOwnProperty('addModule')) {
      this.dialog = true;
    }
    if (this.$route.query.hasOwnProperty('contact')) {
      this.searchFormData.contactIdSearch = parseInt(this.$route.query.contact);
    }
    if (this.$route.query.hasOwnProperty('project')) {
      let project = parseInt(this.$route.query.project)
      this.searchFormData.location_id = [project];
      this.searchFormData.location_ref = "project";
    }
    if (this.$route.query.hasOwnProperty('development')) {
      let development = parseInt(this.$route.query.development)
      this.searchFormData.location_id = [development];
      this.searchFormData.location_ref = "development";
    }
    if (this.$route.query.hasOwnProperty('domain')) {
      let domain = parseInt(this.$route.query.domain)
      this.searchFormData.domainSearch = domain;
    }
    if (this.$route.query.hasOwnProperty('role')) {
      let role = parseInt(this.$route.query.role)
      this.searchFormData.roleSearch = role;
    }
    // this.showTree(0, '', 'all');
    this.loadData().then(response => {
      this.items = response.items;
      this.totalRecords = response.total;
      this.getContactRoles();
    });
    if (this.$route.query.hasOwnProperty('share') && this.$route.query.share === 'report') {
      this.emailMsg = this.$store.state.reports.sharedReportLink
      Swal.fire('', 'Select contacts to share report link via email')
    }
    if (this.$route.query.hasOwnProperty('share') && this.$route.query.share === 'listing' && this.$route.query.hasOwnProperty('rid')) {
      Swal.fire('', 'Select contacts to share listings via email')
      this.getListingsByIds(this.$route.query.rid)
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      this.items = [];
      this.mainDataTableSelection = [];
      return new Promise((resolve) => {
        // if (this.searchFormData.location_ref && this.searchFormData.projectSearch !== 'null' && this.searchFormData.projectSearch !== 'undefined' && this.searchFormData.projectSearch !== '') {
        //   this.searchFormData.developmentSearch = ''
        // }
        // if (this.searchFormData.developmentSearch && this.searchFormData.developmentSearch !== 'null' && this.searchFormData.developmentSearch !== 'undefined' && this.searchFormData.developmentSearch !== '') {
        //   this.searchFormData.projectSearch = ''
        // }
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 1 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(CONTACTS_LIST, {q: query, columns: this.columns}).then((res) => {
          // this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = false;
          this.isLoaded = false;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        if (loadDep) this.getContactRoles();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(type, item) {
      this.$store.dispatch(GET_CONTACT_DETAILS, item.id).then((data) => {
        if (type === 'edit') {
          let parentArr = [];
          let tlnArr = [];
          let ornArr = [];
          let brnArr = [];
          let drnArr = [];
          this.editedItem = data.records || {};
          if (data.records.type_of_contact === '0') {
            this.contactTypeIsInd = true;
            this.nameLabel = 'Full Name'
          } else {
            this.contactTypeIsInd = false;
            this.nameLabel = 'Company Name'
          }
          if (data.records.parent) {
            parentArr.push(data.records.parent)
            this.contactsFilter = parentArr;
          }
          if (data.records.tln_authority) {
            tlnArr.push(data.records.tln_authority)
            this.tlnAuthorityFilter = tlnArr;
          }
          if (data.records.orn_authority) {
            ornArr.push(data.records.orn_authority)
            this.ornAuthorityFilter = ornArr;
          }
          if (data.records.brn_authority) {
            brnArr.push(data.records.brn_authority)
            this.brnAuthorityFilter = brnArr;
          }
          if (data.records.drn_authority) {
            drnArr.push(data.records.drn_authority)
            this.drnAuthorityFilter = drnArr;
          }
          this.dialog = true;
          this.showUploadedFiles = true;
        } else {
          this.viewItem = data.records || {};
          this.viewDialog = true;
        }
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_CONTACT;
        let id = item.id;
        this.$set(item, "image", this.$store.state.uploads.uploadedStateFiles);
        this.$set(item, "contact_files", this.$store.state.uploads.uploadedStateOtherFiles);
        this.$set(item, "contact_links", this.$store.state.uploads.uploadedStateLinks);
        this.$set(item, "contact_details", this.$store.state.contacts.uploadedContactDetails);
        this.$set(item, "contact_bank_details", this.$store.state.contacts.uploadedContactBankDetails);
        this.$set(item, "contact_card_details", this.$store.state.contacts.uploadedContactCardDetails);
        // this.$set(item, "contact_comments", this.$store.state.contacts.uploadedContactComments);
        let data = {
          data: item
        };
        if (id) {
          // if the item has an id, we're updating an existing item
          method = UPDATE_CONTACT;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_CONTACT_ERRORS);
        // save the record
        this.$store.dispatch(method, {'slug': id, data: data.data}).then((res) => {
          if (method === 'update_contact') {
            let fItem = this.items.find(ele => ele.id === res.records.id);
            if (fItem) {
              fItem.full_name = res.records.full_name;
              fItem.contact_role_titles = res.records.contact_role_titles;
              fItem.contact_domain_titles = res.records.contact_domain_titles;
              fItem.contact_occupation_titles = res.records.contact_occupation_titles;
              fItem.nationality = res.records.nationality;
              fItem.salutation = res.records.salutation;
              fItem.source = res.records.source;
              fItem.emails = res.records.emails;
              fItem.phones = res.records.phones;
              fItem.passport_numbers = res.records.passport_numbers;
              fItem.nationalities = res.records.nationalities;
              fItem.national_ids = res.records.national_ids;
              fItem.websites = res.records.websites;
            } else {
              bus.$emit("contactUpdatedByParent", res);
            }
          } else {
            this.items.unshift(res.records)
            this.totalRecords = this.totalRecords + 1;
          }
          this.dialog = !this.dialog
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete '" + item.full_name + "'?", clr: 'red', callback: function () {
          this.$store.dispatch(DELETE_CONTACT, {
            'id': item.id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    getContactRoles(loadNext = true) {
      this.$store.dispatch(CONTACTROLES_SIMPLE_LIST).then((data) => {
        this.contactRolesFilter = data.records.contactRolesList
        if (loadNext) this.getDomains()
      });
    },
    getDomains(loadNext = true) {
      this.$store.dispatch(DOMAINS_SIMPLE_LIST).then((data) => {
        this.domainsFilter = data.records.domainsList
        if (loadNext) this.getCommentTitles()
      });
    },
    getCommentTitles(loadNext = true) {
      this.$store.dispatch(COMMENTTITLES_SIMPLE_LIST).then((data) => {
        this.cTFilter = data.records.commentTitlesList;
        if (loadNext) this.getSalutations();
      });
    },
    getSalutations(loadNext = true) {
      this.$store.dispatch(SALUTATIONS_SIMPLE_LIST).then((data) => {
        this.salutationFilter = data.records.salutationsList;
        if (loadNext) this.getStaff();
      });
    },
    getStaff(loadNext = true) {
      this.$store.dispatch(STAFF_LIST).then((data) => {
        this.staffFilter = data.records.staffList;
        if (loadNext) this.getSources()
      });
    },
    // getVendors(loadNext = true) {
    //   this.$store.dispatch(VENDORS_SIMPLE_LIST).then((data) => {
    //     this.vendorsFilter = data.records.vendorsList;
    //     if (loadNext) this.getSources()
    //   });
    // },
    getSources(loadNext = true) {
      this.$store.dispatch(SOURCES_SIMPLE_LIST).then((data) => {
        this.sourcesFilter = data.records.sourcesList;
        if (loadNext) this.getPropertyTypes()
      });
    },
    getPropertyTypes(loadNext = true) {
      let that = this;
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'Properties', is_property: 1}).then((data) => {
        data.records.developmentTypesList.forEach(function (v) {
          if (v.parent_id) {
            that.ptFilter.push(v)
          }
        })
        if (loadNext) this.getOccupations()
      });
    },
    getOccupations(loadNext = true) {
      this.$store.dispatch(OCCUPATIONS_SIMPLE_LIST).then((data) => {
        this.occupationsFilter = data.records.occupationsList
        if (loadNext) this.getCountries()
      });
    },
    getCountries() {
      this.$store.dispatch(COUNTRIES_SIMPLE_LIST).then((data) => {
        this.countryFilter = data.records.countriesList
      });
    },
    contactsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isContactsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.contactsFilter = data.records.contactsList;
                that.isContactsLoaded = false;
              });
        }
      }, 900);
    },
    parentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isParentLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.parentFilter = data.records.contactsList;
                that.isParentLoaded = false;
              });
        }
      }, 900);
    },
    tlnAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isTlnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.tlnAuthorityFilter = data.records.contactsList;
                that.isTlnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    ornAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isOrnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.ornAuthorityFilter = data.records.contactsList;
                that.isOrnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    brnAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isBrnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.brnAuthorityFilter = data.records.contactsList;
                that.isBrnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    drnAuthorityFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDrnAuthorityLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'All',
                q: e.target.value
              })
              .then(data => {
                that.drnAuthorityFilter = data.records.contactsList;
                that.isDrnAuthorityLoaded = false;
              });
        }
      }, 900);
    },
    resetSearchForm(reset = false) {
      this.pagination.page = 1
      this.searchFormData = {
        location_id: '',
        location_ref: '',
        contactIdSearch: '',
        parentSearch: '',
        typeOfContactSearch: '',
        fullNameSearch: '',
        emailSearch: '',
        phoneSearch: '',
        countrySearch: '',
        sourceSearch: '',
        ptSearch: '',
        ctSearch: '',
        occupationSearch: [],
        domainSearch: '',
        bedSearch: '',
        tenancySearch: '',
        abSearch: '',
        cabSearch: '',
        roleSearch: '',
        commentSortSearch: '',
        cdfSearch: '',
        cdtSearch: '',
        drSearch: '',
        sortSearch: ''
      };
      // if (reset) {
        // this.searchFormData = {};
        // this.showTree(0, '', 'all');
        // this.treeItemsPrev = [{id: '0', type: 'all'}]
      // }
      this.advSHitted = !this.advSHitted
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    typeOfContactChange(v) {
      if (v && v === '0') {
        this.contactTypeIsInd = true;
        this.nameLabel = 'Full Name'
      } else {
        this.contactTypeIsInd = false;
        this.nameLabel = 'Company Name'
      }
    },
    hitAdvanceSearch() {
      this.pagination.page = 1
      this.advSHitted = !this.advSHitted;
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
      // if (this.searchFormData.location_ref) {
      //   this.showTree(this.searchFormData.location_id, this.searchFormData.commercial_title, this.searchFormData.location_ref);
      //   this.treeItemsPrev = [{id: '0', type: 'all'}]
      // } else {
      //   this.showTree(0, '', 'all');
      //   this.loadData().then(response => {
      //     this.items = response.items;
      //     this.totalRecords = response.total;
      //   });
      // }
    },
    showShareDialog() {
      this.shareDialog = true;
      // this.contactSharingIds = this.$store.state.auth.user.contact_sharing_ids
    },
    shareContacts() {
      this.$store.dispatch(CLEAR_CONTACT_ERRORS);
      this.$store.dispatch(SHARE_CONTACTS, {
        'share_with': this.contactSharingWith,
        'type': this.contactSharingType,
        'dev_ids': this.contactDevelopmentIds,
        'pro_ids': this.contactProjectIds,
        'con_ids': this.contactCountyIds,
        'user_ids': this.contactSharingIds,
        'vendor_ids': this.contactSharingVendorIds
      }).then(() => {
        // this.$store.dispatch(VERIFY_AUTH)
        // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        this.shareDialog = false
      })
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopmentsLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                q: e.target.value
              })
              .then(data => {
                that.developmentsFilter = data.records.developmentsList;
                that.isDevelopmentsLoaded = false;
              });
        }
      }, 900);
    },
    projectsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isProjectsLoaded = true;
          that.$store
              .dispatch(PROJECTS_SIMPLE_LIST, {
                q: e.target.value,
                type: 'none'
              })
              .then(data => {
                that.projectsFilter = data.records.projectsList;
                that.isProjectsLoaded = false;
              });
        }
      }, 900);
    },
    refreshComments(comment, item, load = false) {
      if (load) {
        item.contact_comments = comment
        item.comment_count = item.contact_comments.length
      } else {
        item.contact_comments = [comment, ...item.contact_comments]
        item.comment_count++;
      }
    },
    refreshReminders(comment, item) {
      item.contact_reminders = [comment, ...item.contact_reminders]
      // item.contact_comments.push(comment);
      item.reminder_count++;
    },
    updateSharedContacts(item) {
      this.showEditDialog(item)
    },
    verifyNumbers() {
      this.$store.dispatch(VERIFY_NUMBERS, {contactIds: this.mainDataTableSelection}).then((data) => {
        this.mainDataTableSelection = []
        this.smsDialog = true
        this.vFN = data.records.phone_numbers
      })
    },
    verifyEmails() {
      this.$store.dispatch(VERIFY_EMAILS, {contactIds: this.mainDataTableSelection}).then((data) => {
        this.mainDataTableSelection = []
        this.emailDialog = true
        this.vEmails = data.records.emails
      })
    },
    showTree(id, title, type, loc = 'null', obj = {}) {
      this.treeLoading = true
      this.pagination.page = 1
      if (id !== 0) {
        if (type === 'project') {
          this.searchFormData.location_ref = "project";
        } else {
          this.searchFormData.location_ref = "development";
        }
        this.searchFormData.location_id = id
        if (obj.hasOwnProperty('id')) this.locationsFilter = [{id: obj.id, text: obj.title}]
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
          this.treeLoading = false
        });
      }
      if (type === 'development' || type === 'all') {
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.$store.dispatch(DEVELOPMENTS_WITH_COUNT, {
          type: 'contacts',
          'id': id,
          'get': type,
          query: searchFormDataQuery
        })
            .then((data) => {
              if (loc === 'go') this.treeItemsPrev = [{id: id, title: title, type: type}, ...this.treeItemsPrev];
              this.treeItems = data.records.developmentsList
              if (loc === 'back') {
                if (this.treeItemsPrev.length > 2) {
                  if (type === 'project') {
                    this.searchFormData.location_ref = "project";
                  } else {
                    this.searchFormData.location_ref = "development";
                  }
                  this.searchFormData.location_id = id
                  this.locationsFilter = [{id: this.treeItemsPrev[1].id, text: this.treeItemsPrev[1].title}]
                }
                this.treeItemsPrev.shift()
              }
              if (this.treeItems.length > 12) {
                this.showMore = true
                this.showLess = false
                this.showClass = "mrc-show-less"
              }
              this.treeLoading = false
            })
      }
    },
    viewListingFromComment(contact, listingId) {
      this.cListingId = listingId
      this.editedItem = contact
      this.propsDialog = true
    },
    getListingsByIds(id) {
      this.$store.dispatch(LISTINGS_SIMPLE_BY_IDS_LIST, {'ids': [id]})
          .then((data) => {
            this.sharedListings = data.records.listingsList;
          })
    },
    locationsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isLocationssLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_AND_PROJECTS_SIMPLE_LIST, {
                type: 'all',
                q: e.target.value,
                countryId: 188,
                cityId: 1
              })
              .then(data => {
                that.locationsFilter = data.records.results;
                that.isLocationssLoaded = false;
              });
        }
      }, 900);
    },
    locationChange(e) {
      if (e) {
        this.searchFormData.location_id = e.id
        this.searchFormData.location_ref = e.location_ref
      } else {
        this.searchFormData.location_id = ''
        this.searchFormData.location_ref = ''
      }
    },
    drChange(v) {
      if (v) {
        if (v === "1") {
          let date = new Date();
          let cdate = new Date();
          let lastmonths = new Date(cdate.setMonth(cdate.getMonth() - 1));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = lastmonths.getFullYear() + '-' + ('0' + (lastmonths.getMonth() + 1)).slice(-2) + '-' + ('0' + lastmonths.getDate()).slice(-2);
          this.searchFormData.cdtSearch = toDate
          this.searchFormData.cdfSearch = fromDate
        } else if (v === "2") {
          let date = new Date();
          let cdate = new Date();
          let last3months = new Date(cdate.setMonth(cdate.getMonth() - 2));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last3months.getFullYear() + '-' + ('0' + (last3months.getMonth())).slice(-2) + '-' + ('0' + last3months.getDate()).slice(-2);
          this.searchFormData.cdtSearch = toDate
          this.searchFormData.cdfSearch = fromDate
        } else if (v === "3") {
          let date = new Date();
          let cdate = new Date();
          let last6months = new Date(cdate.setMonth(cdate.getMonth() - 5));
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = last6months.getFullYear() + '-' + ('0' + (last6months.getMonth())).slice(-2) + '-' + ('0' + last6months.getDate()).slice(-2);
          this.searchFormData.cdtSearch = toDate
          this.searchFormData.cdfSearch = fromDate
        } else if (v === "4") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = (date.getFullYear() - 1) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          this.searchFormData.cdtSearch = toDate
          this.searchFormData.cdfSearch = fromDate
        } else if (v === "5") {
          let date = new Date();
          let toDate = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
          let fromDate = date.getFullYear() + '-01-01';
          this.searchFormData.cdtSearch = toDate
          this.searchFormData.cdfSearch = fromDate
        }
      } else {
        this.searchFormData.cdtSearch = ''
        this.searchFormData.cdfSearch = ''
      }
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    filteredResources() {
      if (this.searchQueryTree) {
        return this.treeItems.filter((item) => {
          return this.searchQueryTree.toLowerCase().split(' ').every(v => item.title.toLowerCase().includes(v))
        })
      } else {
        return this.treeItems;
      }
    },
    ...mapState({
      errors: state => state.contacts.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>
<template>
  <v-col cols="12">
    <h4>{{ moduleLabel }}</h4>
    <div class="form-group" v-for="(detail,k) in details" :key="k" style="margin: 0">
      <v-row v-if="moduleTitle === 'Phone'">
        <v-col cols="12" sm="4">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.country_id"
              :items="countriesFilter"
              item-text="full_title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Country"
              @change="countriesFilterChange($event, k)"
              :append-icon="'mdi-refresh'"
              @click:append="getCountries(false)"
          >
            <template v-slot:append-outer>
              <a target="_blank" href="/setup/countries?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              :label="moduleTitle"
              color="primary"
              maxlength="500"
              :counter="500"
              @keyup="update"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.label_id"
              :items="contactLabelsFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Label"
              :prepend-icon="'mdi-refresh'"
              @click:prepend="getLabels(false)"
          >
            <template v-slot:append>
              <a target="_blank" href="/contacts-management/labels?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-else-if="moduleTitle === 'Address'">
        <v-col cols="12" sm="3">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.country_id"
              :items="countriesFilter"
              item-text="full_title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Country"
              :append-icon="'mdi-refresh'"
              @click:append="getCountries(false)"
          >
            <template v-slot:append-outer>
              <a target="_blank" href="/setup/countries?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
              :ref="'city_' + k"
              v-model="detail.city_id"
              :items="citiesFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              deletable-chips
              chips
              small-chips
              label="City"
          >
            <template v-slot:append-outer>
              <a target="_blank" href="/setup/cities?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              label="Postal Code"
              color="primary"
              maxlength="20"
              :counter="20"
              @keyup="update"
              v-model="detail.postal_code">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              label="PO Box"
              color="primary"
              maxlength="20"
              :counter="20"
              @keyup="update"
              v-model="detail.po_box">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              :label="moduleTitle"
              color="primary"
              maxlength="500"
              :counter="500"
              @keyup="update"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.label_id"
              :items="contactLabelsFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Label"
              :prepend-icon="'mdi-refresh'"
              @click:prepend="getLabels(false)"
          >
            <template v-slot:append>
              <a target="_blank" href="/contacts-management/labels?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-else-if="moduleTitle === 'Passport Number'">
        <v-col cols="12" sm="6">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              :label="moduleTitle"
              color="primary"
              maxlength="500"
              :counter="500"
              @keyup="update"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.country_id"
              :items="countriesFilter"
              item-text="full_title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Nationality"
              :append-icon="'mdi-refresh'"
              @click:append="getCountries(false)"
          >
            <template v-slot:append->
              <a target="_blank" href="/setup/countries?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-else-if="moduleTitle === 'Event'">
        <v-col cols="12" sm="4">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              :label="moduleTitle"
              color="primary"
              maxlength="500"
              :counter="500"
              @keyup="update"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              type="date"
              label="Date"
              color="primary"
              @keyup="update"
              v-model="detail.date">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.label_id"
              :items="contactLabelsFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Label"
              :prepend-icon="'mdi-refresh'"
              @click:prepend="getLabels(false)"
          >
            <template v-slot:append>
              <a target="_blank" href="/contacts-management/labels?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" sm="6">
          <v-text-field
              v-show="detail.type === moduleTitle"
              clearable
              outlined
              dense
              :label="moduleTitle"
              color="primary"
              maxlength="500"
              :counter="500"
              @keyup="update"
              v-model="detail.title">
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
              v-show="detail.type === moduleTitle"
              v-model="detail.label_id"
              :items="contactLabelsFilter"
              item-text="title"
              item-value="id"
              clearable
              outlined
              dense
              chips
              small-chips
              deletable-chips
              label="Label"
              :prepend-icon="'mdi-refresh'"
              @click:prepend="getLabels(false)"
          >
            <template v-slot:append>
              <a target="_blank" href="/contacts-management/labels?addModule=true">
                <v-icon>mdi-plus-circle-outline</v-icon>
              </a>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title
                      v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
            <template v-slot:append-outer>
              <v-btn
                  @click="remove(k)"
                  v-show="k || ( !k && details.length > 1)"
                  small
                  dark
                  color="red"
                  class="mr-1">
                <v-icon small dark>fas fa-minus-circle</v-icon>
              </v-btn>
              <v-btn
                  @click="add(k)"
                  v-show="k == details.length-1"
                  small
                  color="success"
                  class="ma-0">
                <v-icon small dark>fas fa-plus-circle</v-icon>
              </v-btn>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>

<script>
import {bus} from "@/main";
import {LABELS_SIMPLE_LIST} from "@/core/services/store/labels.module";
import {COUNTRIES_SIMPLE_LIST} from "@/core/services/store/countries.module";
import {CITYS_SIMPLE_LIST} from "@/core/services/store/cities.module";

export default {
  name: 'contact-details',
  props: ['moduleOf', 'moduleFor', 'moduleLabel', 'moduleTitle', 'contactDetailsOld'],
  data() {
    return {
      contactLabelsFilter: [],
      countriesFilter: [],
      citiesFilter: [],
      uploadedContactDetails: [],
      details: [{
        title: '',
        label_id: '',
        country_id: '',
        city_id: '',
        label: '',
        date: '',
        postal_code: '',
        po_box: '',
        type: this.moduleTitle,
        model: this.moduleOf
      }]
    }
  },
  watch: {
    details: {
      handler(v) {
        Object.assign(this.$store.state.contacts.uploadedContactDetails, {[this.moduleFor]: v});
      },
      deep: true
    },
    contactDetailsOld: {
      handler() {
        if (this.contactDetailsOld.length > 0) {
          this.details = this.contactDetailsOld
        } else {
          this.details = [{
            title: '',
            label_id: '',
            country_id: '',
            city_id: '',
            label: '',
            date: '',
            postal_code: '',
            po_box: '',
            type: this.moduleTitle,
            model: this.moduleOf
          }]
        }
        // this.uploadedLinksOld = this.contactDetailsOld;
      },
      deep: true
    }
  },
  mounted() {
    if (this.contactDetailsOld.length > 0) {
      this.details = this.contactDetailsOld
      if (this.moduleTitle === 'Address') {
        this.details.findIndex(element => {
          this.citiesFilter.push(element.city)
        });
      }
    } else {
      this.details = [{
        title: '',
        label_id: '',
        country_id: '',
        city_id: '',
        label: '',
        date: '',
        postal_code: '',
        po_box: '',
        type: this.moduleTitle,
        model: this.moduleOf
      }]
    }
    bus.$on("contactDetailsDeletedByModal", () => {
      this.details = [{
        title: '',
        label_id: '',
        country_id: '',
        city_id: '',
        label: '',
        date: '',
        postal_code: '',
        po_box: '',
        type: this.moduleTitle,
        model: this.moduleOf
      }];
      this.$store.state.contacts.uploadedContactDetails = {};
    });
    this.getLabels();
  },
  methods: {
    add() {
      this.details.push({
        title: '',
        label_id: '',
        country_id: '',
        city_id: '',
        label: '',
        date: '',
        postal_code: '',
        po_box: '',
        type: this.moduleTitle,
        model: this.moduleOf
      });
    },
    update() {
    },
    remove(index) {
      this.details.splice(index, 1);
    },
    getLabels(loadNext = true) {
      this.$store.dispatch(LABELS_SIMPLE_LIST, {type: this.moduleTitle}).then((data) => {
        this.contactLabelsFilter = data.records.labelsList;
        if ((loadNext && this.moduleTitle === 'Phone') || (loadNext && this.moduleTitle === 'Address') || (loadNext && this.moduleTitle === 'Passport Number')) this.getCountries();
      });
    },
    getCountries(loadNext = true) {
      this.$store.dispatch(COUNTRIES_SIMPLE_LIST).then((data) => {
        this.countriesFilter = data.records.countriesList;
        if (loadNext && this.moduleTitle === 'Address') this.getCities();
      });
    },
    getCities() {
      this.$store.dispatch(CITYS_SIMPLE_LIST, {country_id: 'none'}).then((data) => {
        this.citiesFilter = data.records.citiesList;
      });
    },
    countriesFilterChange(id, ind) {
      this.countriesFilter.findIndex(element => {
        if (element.id === id) {
          this.details[ind].title = element.dial_code
        }
      });
    },
    // countrySelected(id, ind) {
    //   if (id) {
    //     this.$store.dispatch(CITYS_SIMPLE_LIST, {country_id: id}).then((data) => {
    //       this.citiesFilter = data.records.citiesList;
    //     });
    //   }
    // }
  }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',[_c('v-card-title',[_vm._v(" Contacts Shared With ("+_vm._s(_vm.addTSBVZ(_vm.totalRecords))+") ")]),_c('v-data-table',{ref:"mrc-contacts-properties-datatable",staticClass:"elevation-0 custom-td-table custom-td-table-scroll",attrs:{"headers":_vm.headers,"items":_vm.items,"dense":"","options":_vm.pagination,"server-items-length":_vm.totalRecords,"footer-props":{
                  itemsPerPageText:'Page no. ' + _vm.params.page + ' - Rows per page',
                  itemsPerPageOptions: _vm.rowsPerPageItems,
                  showFirstLastPage: true,
                },"loading":_vm.loading,"mobile-breakpoint":"100"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',{domProps:{"innerHTML":_vm._s(item.user && item.user.vendor ? item.user.vendor.name + ' >> ' + item.user.name + ' >> ' + item.user.email : '-')}})])]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"red","dark":"","x-small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" Remove "),_c('v-icon',{attrs:{"dark":"","right":"","x-small":""}},[_vm._v("fas fa-trash")])],1)],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }